import React from "react";
import heroBackground from "../../assets/images/hero-background.png";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { PRODUCTS } from "../../constants/products";
import Slider from "react-slick";
import { NAVIGATIONS } from "../..";
import { useNavigate } from "react-router";
import elefinLogo from "../../assets/images/elefin-white.png";
import legalLogo from "../../assets/images/legal.png";
import { ELEFIN_HOME } from "../../constants";

function HomeBanner() {
  const navigate = useNavigate();
  return (
    <div
      className="hero-background-container home-banner position-relative"
      style={{
        backgroundImage: `url(${heroBackground})`,
      }}
    >
      <Container>
        <div className="text-white text-size-body-head-2 pt-5 pt-lg-4 pt-xl-5 home-banner-info">
          Penyedia Layanan Finansial
        </div>
        <Slider
          arrows={false}
          dots={false}
          fade
          infinite
          speed={500}
          slidesToScroll={1}
          slidesToShow={1}
          autoplay
          autoplaySpeed={5000}
          pauseOnFocus={false}
          pauseOnHover={false}
        >
          {Object.values(PRODUCTS).map((product) => {
            return (
              <div key={`banner-${product.key}`}>
                <Row className="text-white">
                  <Col
                    xl={7}
                    lg={6}
                    md={10}
                    sm={12}
                    className="h-100 mt-xl-5 pt-lg-5"
                  >
                    <div>
                      <div>
                        <h1 className="text-size-title-2 fw-extra-bold banner-product-title">
                          {product.title}
                        </h1>
                        <div className="mt-2 text-size-body-head-2">
                          {product.bannerDescription}
                        </div>
                      </div>
                      <div className="mt-4 mt-lg-4 d-flex d-xl-block justify-content-start">
                        <div className="text-size-body-head-2">
                          <Button
                            className="btn-white"
                            onClick={() => {
                              if (!NAVIGATIONS[product.key]) {
                                return;
                              }
                              navigate(NAVIGATIONS[product.key]);
                            }}
                          >
                            Pelajari lebih lanjut
                          </Button>
                        </div>
                        {product.key === "poFinancing" && (
                          <div className="ms-4 ms-xs-5 ms-xl-0 mt-0 mt-xl-4 text-size-caption">
                            <div className="mb-2">Premium partner</div>
                            <a
                              href={ELEFIN_HOME}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Image src={elefinLogo} fluid />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xl={5}
                    lg={6}
                    className="d-none d-lg-flex justify-content-end text-end pt-lg-5"
                    style={{ overflow: "hidden" }}
                  >
                    <Image src={product.bannerImage} fluid />
                  </Col>
                </Row>
              </div>
            );
          })}
        </Slider>
        <div
          className="mt-4 d-flex align-items-center position-absolute"
          style={{ bottom: 12 }}
        >
          <div className="me-2">
            <div className="text-size-caption fw-extra-bold text-secondary">
              Berizin & Diawasi Oleh
            </div>
            <div className="text-size-caption fw-extra-bold text-secondary">
              Otoritas Jasa Keuangan
            </div>
          </div>
          <div>
            <Image src={legalLogo} fluid />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default HomeBanner;

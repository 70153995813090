import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ProductModal from "./products/product-modal";

interface IProps {
  caption?: string;
  title: string;
  buttonText: string;
}

function GeneralClosing({ caption, title, buttonText }: IProps) {
  const [showRegistration, setShowRegistration] = useState(false);
  return (
    <div className="my-4 py-4">
      <Row className="justify-content-center">
        <Col xl={9} lg={10} md={10} sm={12}>
          {caption && (
            <div className="mb-2 text-size-body-head text-secondary text-center">
              {caption}
            </div>
          )}

          <div className="mb-5 text-size-title-2 fw-extra-bold text-primary-main text-center">
            {title}
          </div>
          <div className="text-center">
            <Button
              variant="primary"
              size="lg"
              onClick={() => setShowRegistration(true)}
            >
              {buttonText}
            </Button>
          </div>
        </Col>
      </Row>
      {showRegistration && (
        <ProductModal onHide={() => setShowRegistration(false)} />
      )}
    </div>
  );
}

export default GeneralClosing;

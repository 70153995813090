import React from 'react';
import { CONTACTS } from '../constants';
import { FaPhone, FaRegClock, FaRegEnvelope } from 'react-icons/fa'

function GeneralSupport({containerClass = "", }) {
  return (
    <div className={containerClass}>
      <div className="d-flex align-items-center gap-2 mb-2">
        <FaPhone className="text-size-caption" /> {CONTACTS.generalSupport}
      </div>
      <div className="d-flex align-items-center gap-2 mb-2">
        <FaRegEnvelope className="text-size-caption" /> {CONTACTS.email}
      </div>
      <div className="d-flex align-items-center gap-2">
        <FaRegClock className="text-size-caption" /> {CONTACTS.operationalTime}
      </div>
    </div>
  )
}

export default GeneralSupport;

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import heroBackground from "../../assets/images/hero-background-about.png";
import faqHeader from "../../assets/images/faq.png";
import FaqContent from "./faq-content";
function Faq() {
  return (
    <div className="main-container" id="faq-page">
      <div
        className="hero-background-container faq-banner position-relative"
        style={{
          backgroundImage: `url(${heroBackground})`,
        }}
      >
        <Container>
          <div>
            <Row className="text-white justify-content-between">
              <Col
                xl={7}
                lg={6}
                md={12}
                sm={12}
                className="h-100 mt-xl-5 pt-lg-5"
              >
                <div>
                  <div>
                    <h1 className="text-size-title-2 fw-extra-bold banner-product-title">
                      Frequently Asked
                    </h1>
                    <h1 className="text-size-title-2 fw-extra-bold banner-product-title">
                      Questions
                    </h1>
                    <div className="mt-2 text-size-body-head-2 text-white">
                      Butuh informasi lengkap tentang Pohon Dana?
                    </div>
                    <div className="mt-1 text-size-body-head-2 text-white">
                      Temukan jawabannya disini!
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xl={5}
                lg={6}
                className="d-none d-lg-flex justify-content-end text-end pt-lg-5"
                style={{ overflow: "hidden" }}
              >
                <Image src={faqHeader} fluid />
              </Col>
            </Row>
          </div>
        </Container>
      
      </div>
      <Container className="mb-5 py-5">
          <FaqContent/>
        </Container>
    </div>
  );
}

export default Faq;

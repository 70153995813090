import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import contactImage from "../../assets/images/contact-image.png";
import LayananPeminjam from "../../components/layanan-peminjam";
import GeneralSupport from "../../components/general-support";
import ContactForm from "./contact-form";

function Contact() {
  return (
    <div className="main-container" id="contact-page">
      <Container className="my-5">
        <div className="contact-content">
          <Row>
            <Col
              xl={6}
              lg={6}
              className="d-none d-lg-flex justify-content-start text-end pt-lg-5"
            >
              <Image src={contactImage} fluid />
            </Col>
            <Col xl={6} lg={6} md={12}>
              <ContactForm />
            </Col>
          </Row>
        </div>
      </Container>
      <div className="contact-info-container mt-4 mt-lg-5 mb-5 position-relative">
        <Container>
          <div className="border border-primary-main rounded-4 p-4 border-2 bg-white">
            <Row>
              <Col lg={4} md={12} sm={12} className="mb-4 mb-lg-0">
                <div className="fw-bold text-size-caption mb-2">
                  Layanan Peminjam
                </div>
                <LayananPeminjam />
              </Col>
              <Col lg={4} md={12} sm={12} className="mb-4 mb-lg-0">
                <div className="fw-bold text-size-caption mb-2">
                  General Support
                </div>
                <GeneralSupport />
              </Col>
              <Col lg={4} md={12} sm={12}>
                <div className="fw-bold text-size-caption mb-2">
                  Alamat Kantor
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  MAYAPADA TOWER 1, Lt. 8 Jl. Jendral Sudirman No.Kav. 28,
                  Kuningan, Kota Jakarta Selatan, DKI Jakarta 12920, Indonesia
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Contact;

import React from 'react';
import { CONTACTS } from '../constants';
import { FaRegClock, FaWhatsapp } from 'react-icons/fa'

function LayananPeminjam({containerClass = "", }) {
  return (
    <div className={containerClass}>
      <div className="d-flex align-items-center gap-2 mb-2">
        <FaWhatsapp className="text-size-caption" /> {CONTACTS.whatsapp}
      </div>
      <div className="d-flex align-items-center gap-2">
        <FaRegClock className="text-size-caption" /> {CONTACTS.operationalTime}
      </div>
    </div>
  )
}

export default LayananPeminjam;

import employeeLoanImg from "../assets/images/products/employee-loan.png";
import invoiceFinancingImg from "../assets/images/products/invoice-financing.png";
import poFinancingImg from "../assets/images/products/po-financing.png";
import capitalLoanImg from "../assets/images/products/working-capital-loan.png";
import employeeLoanImgBanner from "../assets/images/products/employee-loan-banner.png";
import invoiceFinancingImgBanner from "../assets/images/products/invoice-financing-banner.png";
import poFinancingImgBanner from "../assets/images/products/po-financing-banner.png";
import capitalLoanImgBanner from "../assets/images/products/working-capital-loan-banner.png";
import { ENV } from "../config/env";
import { ELEFIN_HOME } from ".";


export type ProductInfo = {
  key: string;
  title: string;
  shortDescription: string;
  bannerDescription: string;
  shortImage: string; // Assuming the images are strings, change if they are objects like URLs or imports
  bannerImage: string;
  description: string;
  link: string;
};

export const PRODUCTS: Record<string, ProductInfo> = {
  poFinancing: {
    key: "poFinancing",
    title: "PO Financing",
    shortDescription: "Barang datang lebih awal dengan pembayaran diakhir",
    bannerDescription:
      "Dapatkan barang dulu dan bayar nanti untuk setiap pembelanjaan anda di partner kami",
    shortImage: poFinancingImg,
    bannerImage: poFinancingImgBanner,
    description:
      "Pembiayaan untuk memudahkan membeli barang dalam memenuhi kebutuhan bisnis melalui partner kami. Jatuh tempo yang fleksibel tanpa mengganggu cash flow bisnis.",
    link: ELEFIN_HOME,
  },
  invoiceFinancing: {
    key: "invoiceFinancing",
    title: "Invoice Financing",
    shortDescription: "Ubah invoice anda menjadi dana segar",
    bannerDescription:
      "Dapatkan dana dari invoice yang belum terbayar untuk cashflow bisnis anda",
    shortImage: invoiceFinancingImg,
    bannerImage: invoiceFinancingImgBanner,
    description:
      "Solusi finansial revolusioner yang mengubah invoice anda menjadi dana segar dalam hitungan jam. Tidak perlu menunggu 30, 60, atau bahkan 90 hari untuk mendapatkan pembayaran!",
    link: `${ENV.pohon_dana_url}/register/invoice-financing/borrower/coorporate`,
  },
  capitalLoan: {
    key: "capitalLoan",
    title: "Working Capital Loan",
    shortDescription: "Dapatkan tambahan modal dengan cepat",
    bannerDescription:
      "Kembangkan bisnis anda dengan tambahan modal usaha dari Pohon Dana",
    shortImage: capitalLoanImg,
    bannerImage: capitalLoanImgBanner,
    description:
      "Pinjaman modal kerja yang dibutuhkan untuk menjaga kelancaran operasional sehari-hari, terutama saat menghadapi fluktuasi arus kas atau penurunan musiman.",
    link: `${ENV.pohon_dana_url}/register/new-web/borrower/coorporate`,
  },
  employeeLoan: {
    key: "employeeLoan",
    title: "Employee Loan",
    shortDescription: "Pinjaman Karyawan untuk mitra perusahaan Pohon Dana",
    bannerDescription:
      "Bantu karyawan anda mendapatkan pendanaan tanpa mengganggu cashflow perusahaan",
    shortImage: employeeLoanImg,
    bannerImage: employeeLoanImgBanner,
    description:
      "Disediakan untuk mendukung finansial karyawan perusahaan yang telah bermitra dengan Pohon Dana, dengan syarat yang lebih menguntungkan dibandingkan pinjaman lain.",
    link: `${ENV.pohon_dana_url}/register/borrower/employee`,
  },
  
};

export const EMPLOYEE_LOAN_INFO = {
  adminFee: 50000,
  interestRate: {
    start: 1.8,
    end: 3.5,
  },
};

export const INVOICE_FINANCING_INFO = {
  interestRate: {
    start: 1,
    end: 1.5,
  },
};

export const WORKING_CAPITAL_LOAN_INFO = {
  interestRate: {
    start: 1,
    end: 1.5,
  },
};

import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { ENV } from "../config/env";
import { ADDRESS_STREET, ADDRESS_TOWER, DEFAULT_WEB_TITLE } from "../constants";
import LayananPeminjam from "./layanan-peminjam";
import GeneralSupport from "./general-support";
import { NAVIGATIONS } from "..";
import { Link } from "react-router";
import kanLogo from "../assets/images/kan.png";
import lapssjkLogo from "../assets/images/lapssjk.png";
import legalLogo from "../assets/images/legal.png";
import afpiLogo from "../assets/images/afpi.png";

function Footer() {
  return (
    <div id="site-footer">
      <div className="contact-section py-5">
        <Container>
          <div className="mb-4">
            <Image
              className="logo-footer"
              src={`${ENV.URL}/images/logo-black.png`}
              alt=""
              title={DEFAULT_WEB_TITLE}
            />
          </div>
          <Row className="text-size-label">
            <Col lg={4} md={12} sm={12}>
              <div className="mb-4">
                <div className="fw-bold">{ADDRESS_TOWER}</div>
                <a
                  href="https://www.google.com/maps/place/Mayapada+Tower,+Jl.+Jenderal+Sudirman+No.Kav.28,+RT.4%2FRW.2,+Kuningan,+Karet,+Setiabudi,+South+Jakarta+City,+Jakarta+12920/@-6.2138026,106.8175084,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f401bd149845:0xfab22c8daef828ee!8m2!3d-6.2138079!4d106.8200833!16s%2Fm%2F0406rvp?entry=ttu&g_ep=EgoyMDI1MDEwOC4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                >
                  {ADDRESS_STREET.map((a, aIdx) => (
                    <div key={`address-${aIdx}`}>{a}</div>
                  ))}
                </a>
              </div>
              <Row>
                <Col lg={12} md={6} sm={12}>
                  <div className="ps-4 border-start border-2 border-primary-main h-100">
                    <div className="fw-bold text-size-caption mb-2">
                      Layanan Peminjam
                    </div>
                    <LayananPeminjam />
                  </div>
                </Col>
                <Col lg={12} md={6} sm={12} className="mt-4 mt-md-0 mt-lg-4">
                  <div className="ps-4 border-start border-2 border-secondary-main">
                    <div className="fw-bold text-size-caption mb-2">
                      General Support
                    </div>
                    <GeneralSupport />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={5} md={12} sm={12} className="mt-5 mt-lg-0">
              <Row>
                <Col lg={6} md={6} sm={6}>
                  <div className="fw-bold mb-4 text-size-caption">
                    Info Pohon Dana
                  </div>
                  <div className="mb-2 text-size-caption">
                    <Link to={NAVIGATIONS.about}>Tentang Kami</Link>
                  </div>
                  <div className="mb-2 text-size-caption">
                    <Link to={NAVIGATIONS.contact}>Kontak Kami</Link>
                  </div>
                  <div className="mb-2 text-size-caption">
                    <Link to={NAVIGATIONS.partner}>Partner</Link>
                  </div>
                  <div className="mb-2 text-size-caption">
                    <Link to={NAVIGATIONS.riplay}>
                      Ringkasan Produk <br></br> dan Layanan
                    </Link>
                  </div>
                  <div className="mb-2 text-size-caption">
                    <Link to={NAVIGATIONS.faq}>FAQ</Link>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6} className="mt-4 mt-sm-0">
                  <div className="fw-bold mb-4 text-size-caption">
                    Kebijakan Perusahaan
                  </div>
                  <div className="mb-2 text-size-caption">
                    <Link to={NAVIGATIONS.complaint}>Mekanisme Pengaduan</Link>
                  </div>
                  <div className="mb-2 text-size-caption">
                    <Link to={NAVIGATIONS.tnc}>Syarat &amp; Ketentuan</Link>
                  </div>
                  <div className="mb-2 text-size-caption">
                    <Link to={NAVIGATIONS.privacy}>Kebijakan Privasi</Link>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={12} sm={12} className="mt-5 mt-lg-0">
              <Row>
                <Col
                  lg={12}
                  md={4}
                  sm={12}
                  className="text-center mb-0 mb-md-4 mb-lg-0"
                >
                  <div className="text-size-caption mb-2 text-secondary">
                    Berizin dan diawasi oleh
                  </div>
                  <div className="text-size-caption fw-extra-bold mb-2 text-secondary">
                    Otoritas Jasa Keuangan
                  </div>
                </Col>
                <Col lg={12} md={8} sm={12} className="mt-4 mt-md-0 mt-lg-5">
                  <div className="text-size-caption mb-2 text-secondary text-center">
                    Anggota dari
                  </div>
                  <Row className="justify-content-center align-items-center">
                    <Col xs="auto">
                      <Image src={afpiLogo} fluid />
                    </Col>
                    <Col xs="auto">
                      <Image src={legalLogo} fluid />
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <Col xs="auto">
                      <Image src={lapssjkLogo} fluid />
                    </Col>
                    <Col xs="auto">
                      <Image src={kanLogo} fluid />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact-section-2 py-5">
        <Container>
          <div className="text-size-caption">
            <span className="fw-extra-bold">Perhatian !</span>
            <ol>
              <li>
                Layanan Pendanaan Bersama Berbasis Teknologi Informasi ( LPBBTI
                ) merupakan penyelenggara layanan jasa keuangan untuk
                mempertemukan Pemberi Dana dengan Penerima Pinjaman, sehingga
                segala bentuk risiko atas transaksi tersebut ditanggung oleh
                masing-masing para pihak. Tidak ada Lembaga atau otoritas negara
                yang bertanggung jawab atas risiko gagal bayar yang terjadi.
              </li>
              <li>
                PT Pohon Dana Indonesia selanjutnya disebut sebagai{" "}
                <strong>“Pohon Dana”</strong> merupakan Penyelenggara Layanan
                Pendanaan Bersama Berbasis Teknologi Informasi (LPBBT) yang
                telah berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK)
                berdasarkan surat Keputusan Nomor KEP-126/D.05/2019 serta
                merupakan anggota aktif dari Asosiasi Fintech Pendanaan Bersama
                Indonesia (AFPI).
              </li>
              <li>
                Pohon Dana dengan persetujuan dari masing-masing Pengguna
                (Pemberi Dana dan/atau Penerima Pinjaman) mengakses, memperoleh,
                menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna
                ("Pemanfaatan Data") pada atau di dalam benda, perangkat
                elektronik (termasuk smartphone atau telepon seluler), perangkat
                keras (hardware) maupun lunak (software), dokumen elektronik,
                aplikasi atau sistem elektronik milik Pengguna atau yang
                dikuasai Pengguna, dengan memberitahukan tujuan, batasan dan
                mekanisme Pemanfaatan Data tersebut kepada Pengguna yang
                bersangkutan sebelum memperoleh persetujuan yang dimaksud.
              </li>
              <li>
                Pemberi Dana yang belum memiliki pengetahuan, pemahaman dan
                pengalaman pinjam meminjam dalam penggunaan layanan ini
                disarankan untuk tidak menggunakan layanan ini.
              </li>
              <li>
                Penerima Pinjaman harus mempertimbangkan nilai tingkat suku
                bunga pinjaman dan biaya lainnya sesuai dengan kemampuan dalam
                membayar pinjaman.
              </li>
              <li>
                Setiap kecurangan tercatat secara digital di dunia maya dan
                dapat diketahui masyarakat luas di media sosial
              </li>
              <li>
                Pengguna harus membaca dan memahami informasi ini sebelum
                membuat keputusan menjadi Pemberi Dana ataupun Penerima
                Pinjaman.
              </li>
              <li>
                Pemerintah yaitu dalam hal ini Otoritas Jasa Keuangan, tidak
                bertanggung jawab atas setiap pelanggaran atau ketidakpatuhan
                oleh Pengguna, baik Pemberi Dana maupun Penerima Pinjaman ( baik
                karena kesengajaan atau kelalaian Pengguna) terhadap ketentuan
                peraturan perundang-undangan maupun kesepakatan atau perikatan
                anatara Penyelenggara dengan pemberi Pinjaman dan/atau Penerima
                Pinjaman.
              </li>
              <li>
                Setiap transaksi dan kegiatan pinjam meminjam atau pelaksanaan
                kesepakatan mengenai pinjam meminjam antara atau yang melibatkan
                penyelenggara, pemberi pinjaman, penerima pinjaman wajib
                dilakukan melalui escrow account dan virtual account sebagaimana
                yang diwajibkan berdasarkan Peraturan Otoritas Jasa Keuangan
                Nomor 40 tahun 2024 tentang Layanan Pendanaan Bersama Berbasis
                Teknologi Informasi dan pelanggaran atau ketidakpatuhan terhadap
                ketentuan tersebut merupakan bukti telah terjadinya pelanggaran
                hukum oleh Pohon Dana sehingga Pohon Dana wajib menanggung ganti
                rugi yang diderita oleh masing-masing Pengguna sebagai akibat
                langsung dari pelanggaran hukum tersebut diatas tanpa mengurangi
                hak Pengguna yang menderita kerugian menurut Kitab Undang-Undang
                Hukum Perdata"
              </li>
              <li>
                HATI-HATI, Transaksi ini berisiko tinggi. Anda dapat saja
                mengalami kerugian atau kehilangan uang. Jangan berutang jika
                tidak memiliki kemampuan membayar. Pertimbangkan secara bijak
                sebelum bertransaksi.
              </li>
            </ol>
          </div>
        </Container>
      </div>
      <div className="contact-section-2 py-3">
        <Container>
          <div className="fw-bold mb-4 text-size-caption text-end">
          Copyrights &copy; 2018 Pohon Dana. All Rights Reserved
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import elefinLogo from "../../../assets/images/elefin-black.png";
import imageLists from "../../../assets/images/partner/list.json";
import { Link } from "react-router";
import { NAVIGATIONS } from "../../..";
import { ELEFIN_HOME } from "../../../constants";

function PoFinancingPartner() {
  const foldersToInclude = ["food_beverages", "hotel", "healthcare", "others"];

  const images = Object.entries(imageLists)
    .filter(
      ([folderName, content]) =>
        foldersToInclude.includes(folderName) && content !== null
    )
    .flatMap(([folderName, content]) => {
      return Object.values(content)
        .filter((entry: any) => entry.products.includes(`po-financing`))
        .map((entry: any) => ({
          src: require(
            `../../../assets/images/partner/${folderName}/${entry.file}`
          ),
          products: entry.products,
        }));
    })
    .slice(0, 12);

  return (
    <React.Fragment>
      <Row className="align-items-start mt-5">
        <Col lg={4} md={12} sm={12}>
          <div className="text-size-title-2 fw-extra-bold text-secondary-dark text-center text-lg-start">
            <span className="text-primary-main">Pengguna </span>
          </div>
          <div className="text-size-title-2 fw-extra-bold text-secondary-dark text-center text-lg-start">
            <span>PO Financing </span>
          </div>
          <div className="text-size-sub-body d-flex align-items-center justify-content-center justify-content-lg-start mt-2">
            <span className="text-size-sub-body">Melalui </span>
            <a href={ELEFIN_HOME} target="_blank" rel="noreferrer">
              <Image src={elefinLogo} fluid width={"60%"} className="ms-2" />
            </a>
          </div>
        </Col>
        <Col lg={8} md={12} sm={12} className="mt-4 mt-lg-0 ps-2">
          <Row>
            {images.map((a, idx) => (
              <Col
                xl={2}
                lg={3}
                md={4}
                sm={6}
                xs={12}
                className="mt-4 mb-2"
                key={`partner-${idx}`}
              >
                <div className="text-center">
                  <Image src={a.src} fluid className="mt-2" />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <div className="mt-4 text-end text-size-sub-body-2">
          <Link to={`${NAVIGATIONS.partner}?filter=po-financing`}>
            Tampilkan Semua
          </Link>
        </div>
      </Row>
    </React.Fragment>
  );
}

export default PoFinancingPartner;

import React from "react";
import { Container } from "react-bootstrap";
import { CONTACTS } from "../../constants";

function RiplayContent() {
  return (
    <React.Fragment>
      <Container className="mb-5">
        <div className="text-size-sub-headline-2 text-center text-primary mb-5 pb-4">
          PT Pohon Dana Indonesia selanjutnya disebut sebagai “Pohon Dana”
          merupakan Penyelenggara Layanan Pendanaan Bersama Berbasis Teknologi
          Informasi (LPBBT) yang telah berizin dan diawasi oleh Otoritas Jasa
          Keuangan (OJK) berdasarkan surat Keputusan Nomor KEP-126/D.05/2019
          serta merupakan anggota aktif dari Asosiasi Fintech Pendanaan Bersama
          Indonesia (AFPI). Pohon Dana berkomitmen dalam mematuhi peraturan yang
          berlaku khususnya pada POJK Nomor 10/POJK.05/2022 tentang LPBBTI dan
          POJK 22 Tahun 2023 tentang Pelindungan Konsumen dan Masyarakat di
          Sektor Jasa Keuangan.
        </div>
        <ol className="main-list">
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                Jenis Produk dan Layanan
              </div>
              <div className="text-primary text-size-sub-body-2">
                Jenis Produk dan Layanan yang disediakan oleh Pohon Dana berupa:
              </div>
              <ol className="sub-list lh-100">
                <li className="text-primary text-size-sub-body-2">
                  Employee Loan
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Invoice Financing
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Early Pay for Supplier
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Working Capital
                </li>
              </ol>
              <div className="text-primary text-size-sub-body-2">
                Produk layanan yang disediakan merupakan produk pinjaman tanpa
                agunan dengan metode pembayaran yang dapat disesuaikan dengan
                jenis produk yang dibutuhkan oleh peminjam.
              </div>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                Manfaat, Risiko dan Biaya
              </div>
              <ol className="sub-list lh-100">
                <li>
                  <span className="fw-bold">Manfaat Pinjaman </span>
                  <br />
                  Layanan Pohon Dana dapat diakses dengan mudah dan cepat dengan
                  cara mengunjungi situs web Pohon Dana di www.pohondana.id
                  dengan proses tanpa Jaminan, demi menunjang kebutuhan
                  finansial pengguna baik kebutuhan yang bersifat Produktif
                  maupun Konsumtif.
                </li>
                <li>
                  <span className="fw-bold">Risiko Pinjaman </span>
                  <br />
                  Risiko Pinjaman atau terdapat Risiko gagal bayar yang timbul
                  dari peminjam merupakan tanggung jawab Pemberi Dana, dan
                  Risiko gagal bayar akan mempengaruhi kualitas kredit peminjam
                  yang tercatat pada SLIK OJK dengan kualitas Kurang Baik dan
                  akan menjadi bahan pertimbangan bagi Layanan Jasa Kuangan
                  lainnya atau bank untuk memberikan layanan jasa keuangan.
                </li>
                <li>
                  <span className="fw-bold">Manfaat Ekonomi/ Biaya </span>
                  <br />
                  <ul className="list-circle">
                    <li>
                      Biaya Bunga pinjaman yang diberikan antara 1.25% sd 3%
                      perbulan. Dengan biaya lainnya yang akan di jelaskan pada
                      saat pengajuan pinjaman. Biaya bunga dan biaya lainnya
                      tidak termasuk denda keterlambatan.{" "}
                    </li>
                    <li>
                      Denda keterlambatan 4.5 % perbulan dari jumlah tertunggak
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                Tata Cara dan Persyaratan
              </div>
              <ol className="sub-list lh-100">
                <li className="text-primary text-size-sub-body-2">
                  <span className="fw-bold">Persyaratan Peminjam</span>
                  <br />
                  <ul className="list-circle">
                    <li>Warga Negara Indonesia</li>
                    <li>Berusia 18 Tahun hingga 65 Tahun</li>
                    <li>Menetap di Indonesia</li>
                    <li>Memiliki Kartu Tanda Penduduk</li>
                    <li>Memiliki Rekening Bank an Peminjam</li>
                  </ul>
                </li>
                <li className="text-primary text-size-sub-body-2">
                  <span className="fw-bold">Tata Cara</span>
                  <br />
                  <ul className="list-circle">
                    <li>
                      Pengajuan awal melakukan registrasi di website Pohon Dana
                      dilengkapi data diri
                    </li>
                    <li>
                      Pemeriksaan kelengkapan data yang di submite pada aplikasi
                      Pohon Dana
                    </li>
                    <li>
                      Setelah diverifikasi peminjam dapat mengajukan pinjaman
                      pada aplikasi sesuai dengan kebutuhan limit yang diajukan
                      oleh peminjam
                    </li>
                    <li>
                      Persetujuan Apabila dana yang diajukan disetujui dana akan
                      segera di kirimkan ke rekening Bank peminjam yang
                      terdaftar
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                Informasi Tambahan
              </div>
              <ol className="sub-list lh-100">
                <li className="text-primary text-size-sub-body-2">
                  Ketentuan informasi yang tercantum dalam RIPLAY tersebut
                  merupakan ringkasan informasi layanan dan produk yang
                  disediakan oleh Pohon Dana serta tidak terlepas dan tetap
                  mengacu pada peraturan yang berlaku.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  RIPLAY ini merupakan referensi bagi pengguna terkait informasi
                  layanan yang diberikan oleh Pohon Dana dan bukan suatu bentuk
                  kesepakatan apapun yang bersifat mengikat.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  RIPLAY ini wajib dibaca dan dipahami oleh pengguna sebelum
                  membuat keputusan menjadi pengguna layanan Pohon Dana
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Pengguna menyatakan dengan ini telah membaca, memahami, dan
                  menerima informasi terkait Ringkasan Informasi Produk dan
                  Layanan Pohon Dana
                </li>
              </ol>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                Informasi Layanan Pengaduan dan Produk
              </div>
              <div className="text-primary text-size-sub-body-2">
                Alamat Kantor Pusat PT Pohon Dana Indonesia berada di Mayapada
                Tower 8th Floor, Jl. Jend. Sudirman Kav 28, Jakarta Selatan
                12920
              </div>
              <ol className="sub-list lh-100">
                <li className="text-primary text-size-sub-body-2">
                  <span className="fw-bold">Layanan Telepon</span>:{" "}
                  {CONTACTS.generalSupport}
                  <br />
                  {CONTACTS.operationalTime}
                </li>
                <li className="text-primary text-size-sub-body-2">
                  <span className="fw-bold">Layanan Email</span>:{" "}
                  {CONTACTS.email}
                  <br />
                  {CONTACTS.operationalTime}
                </li>
                <li className="text-primary text-size-sub-body-2">
                  <span className="fw-bold">Layanan WhatsApp</span>
                  <br />
                  Peminjam (Borrower) : {CONTACTS.whatsapp}
                  <br />
                  Peminjam (Lender) : {CONTACTS.whatsappAlt}
                  <br />
                  {CONTACTS.operationalTime}
                </li>
              </ol>
            </div>
          </li>
        </ol>
      </Container>
    </React.Fragment>
  );
}

export default RiplayContent;

import React, { ReactElement } from "react";
import { Col, Image, Row } from "react-bootstrap";

export interface ProductBenefitContent {
  title: string;
  description: string;
  image: string;
}

interface IProps {
  title: ReactElement;
  contents: ProductBenefitContent[];
}

function ProductBenefit({ title, contents }: IProps) {
  let sizeLg = 12 / contents.length;
  if (sizeLg < 4) {
    sizeLg = 4;
  }
  return (
    <React.Fragment>
      <div className="mb-0 mb-xl-4 fw-extra-bold text-size-title-2 text-center">
        {title}
      </div>
      <Row className="justify-content-center">
        {contents.map((c, idx) => {
          let style: any = { marginBottom: 35 };
          if (
            (contents.length % 2 === 1 && idx % 2 === 0) ||
            (contents.length % 2 === 0 && idx % 2 === 1)
          ) {
            style = {
              marginTop: 35,
            };
          }
          return (
            <React.Fragment key={`content-product-benefit-${idx}`}>
              <Col
                xl={sizeLg}
                lg={6}
                md={6}
                style={style}
                className="d-none d-xl-block"
              >
                <div className="position-relative h-100">
                  <div className="product-benefit-card">
                    <div className="position-absolute product-benefit-icon">
                      <Image src={c.image} />
                    </div>
                    <div className="fw-extra-bold text-size-body-head mb-2 text-secondary-dark">
                      {c.title}
                    </div>
                    <div className="text-size-sub-body-2 text-primary">
                      {c.description}
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg={6}
                sm={6}
                xs={12}
                className="d-block d-xl-none mt-0 mt-sm-2"
              >
                <div className="position-relative h-100">
                  <div className="product-benefit-card">
                    <div className="position-absolute product-benefit-icon">
                      <Image src={c.image} />
                    </div>
                    <div className="fw-extra-bold text-size-body-head mb-2 text-secondary-dark">
                      {c.title}
                    </div>
                    <div className="text-size-sub-body-2 text-primary">
                      {c.description}
                    </div>
                  </div>
                </div>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </React.Fragment>
  );
}

export default ProductBenefit;

import React from "react";
import { Container } from "react-bootstrap";
import { CONTACTS } from "../../constants";

function PrivacyContent() {
  return (
    <React.Fragment>
      <Container className="mb-5">
        <div className="text-size-sub-headline-2 text-center text-primary mb-5 pb-4">
          PT Pohon Dana Indonesia selanjutnya disebut sebagai “POHONDANA”
          merupakan Penyelenggara Layanan Pendanaan Bersama Berbasis Teknologi
          Informasi (LPBBTI), yang mana dalam melakukan transaksi pemberian
          pinjaman dilakukan melalui sistem elektronik dengan menggunakan
          internet. Kebijakan Privasi ini menjelaskan bagaimana POHONDANA
          mengumpulkan, menggunakan, mengelola dan melindungi data pribadi
          pengguna ( Pemberi Pinjaman dan Penerima Pinjaman) yang menggunakan
          Layanan Pendanaan Bersama Berbasis Teknologi Informasi (Layanan)
          Sehubungan dengan penggunaan layanan Kami, maka dengan ini Anda selaku
          pengguna menyatakan telah membaca, mengetahui , memahami, bertanggung
          jawab, dan menyetujui seluruh ketentuan Kebijakan Privasi ini maupun
          ketentuan-ketentuan lainnya baik yang muncul dalam website ini maupun
          berdasarkan kebijakan serta ketentuan peraturan perundang-undangan
          yang berlaku.
        </div>
        <ol className="main-list">
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                DATA INFORMASI PRIBADI YANG DIKUMPULKAN
              </div>
              <div className="text-primary text-size-sub-body-2">
                Kami mengumpulkan data pribadi dari pengguna untuk memberikan
                layanan yang lebih baik. Data informasi yang kami kumpulkan
                meliputi :
              </div>
              <ol className="sub-list">
                <li className="text-primary text-size-sub-body-2">
                  Data Identitas Pengguna : Nama,Alamat, Tempat/Tanggal Lahir,
                  dan Nomor KTP
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Data Kontak : Alamat Email, Nomor Telepon, Dan Alamat Domisili
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Data Keuangan : Informasi Rekening Bank, Riwayat Kredit dan
                  Penghasilan
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Data Transaksi : Riwayat Transaksi Pinjaman, Pembayaran dan
                  rincian lainnya terkait aktivitas dalam layanan kami
                </li>
              </ol>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                PENGGUNAAN DATA INFORMASI PRIBADI YANG DIKUMPULKAN
              </div>
              <div className="text-primary text-size-sub-body-2">
                Dalam hal Kami mengumpulkan, memproses, menggunakan, menyimpan,
                mengungkapkan dan/atau melakukan tindakan lain sepanjang relevan
                dengan tujuan dan kepentingan yang ada atas informasi pribadi
                Anda berkenaan dengan hal berikut:
              </div>
              <ol className="sub-list lh-100">
                <li className="text-primary text-size-sub-body-2">
                  Penyediaan Layanan : Mengelola akun pengguna, memproses
                  pinjaman, melakukan verifikasi identitas dan melancarkan
                  transaksi.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Keamanan dan Pencegahan Penipuan : Mendeteksi, mencegah, dan
                  menindaklanjuti pelanggaran terhadap ketentuan layanan,
                  penipuan, pencucian uang, dan kegiatan ilegal lainnya.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Analisis Pengembangan Produk : Mengirimkan informasi mengenai
                  perubahan kebijakan, pemberitahuan layanan, atau penawaran
                  promosi yang relevan dengan pengguna.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Kepatuhan hukum : Dalam keadaan tertentu, kami dapat
                  menggunakan ataupun mengungkapkan Data Pribadi Anda untuk
                  tujuan pelaksanaan ketentuan perundang-undangan yaitu
                  kepentingan pertahanan dan keamanan nasional, kepentingan
                  proses penegakan hukum, kepentingan umum dalam rangka
                  penyelenggaraan negara, kepentingan pengawasan sektor jasa
                  keuangan, moneter, sistem pembayaran, dan stabilisasi sistem
                  keuangan yang dilakukan dalam rangka penyelenggaraan negara,
                  kepentingan statistik, termasuk dalam hal terjadinya sengketa
                  atau proses hukum antara Pengguna dan kami, atau dugaan tindak
                  pidana yaitu penipuan dan pencurian data.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Pemenuhan persyaratan dan kewajiban peraturan
                  perundang-undangan yang berlaku sehubungan dengan kepentingan
                  perpajakan di Indonesia.
                </li>
              </ol>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                PERLINDUNGAN DATA INFORMASI PRIBADI
              </div>
              <div className="text-primary text-size-sub-body-2">
                Kami melindungi data pengguna dengan menerapkan langkah-langkah
                keamanan fisik, administratif, dan teknis sesuai dengan standar
                industri untuk mencegah akses tidak sah, pengungkapan,
                penggunaan atau perubahan data pribadi pengguna.
              </div>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                PEMBAGIAN DATA INFORMASI PRIBADI KEPADA PIHAK KETIGA
              </div>
              <div className="text-primary text-size-sub-body-2">
                Kami dapat membagikan data pribadi kepada Pihak ketiga, dengan
                situasi tertentu termasuk namum tidak terbatas pada :
              </div>
              <ol className="sub-list lh-100">
                <li className="text-primary text-size-sub-body-2">
                  Penyedia Layanan Pihak Ketiga : untuk membantu Kami
                  menjalankan layanan seperti penyedia teknologi, analisis data,
                  atau pembayaran.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Lembaga Pemerintah : Untuk memenuhi kewajiban hukum, misalnya
                  jika diminta oleh lembaga penegak hukum atau regulator
                  terkait.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Penggabungan atau Akuisisi : Dalam hal terjadi penggabungan,
                  akuisis atau penjualan aset, data pengguna dapat dialihkan
                  sebagai bagian dari transaksi tersebut.
                </li>
              </ol>
              <div className="text-primary text-size-sub-body-2">
                Kami tidak akan menjual atau menyewakan data pribadi pengguna
                kepada pihak ketiga tanpa ada persetujuan dari pengguna, kecuali
                jika diwajibkan oleh hukum.
              </div>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                PENYIMPANAN DATA INFORMASI PRIBADI
              </div>
              <div className="text-primary text-size-sub-body-2">
                Data Pribadi yang kami terima, Kami simpan pada pusat data Kami
                serta pusat pemulihan bencana Kami yang berlokasi di wilayah
                Republik Indonesia. Informasi Pribadi akan disimpan selama 5
                (lima) tahun sesuai dengan masa retensi sebagaimana tercantum
                dalam Undang-Undang Nomor 27 Tahun 2022 Tentang Pelindungan Data
                Pribadi.
              </div>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                PENGHAPUSAN INFORMASI DATA PRIBADI
              </div>
              <ol className="sub-list lh-100">
                <li className="text-primary text-size-sub-body-2">
                  Anda dapat mengajukan permohonan penghapusan data pribadi yang
                  anda berikan kepada Kami, dengan cara memberikan pemberitahuan
                  tertulis melalui email customerservice@pohondana.id. Ketentuan
                  terkait penghapusan Informasi Data Pribadi akan dilakukan
                  sesuai dengan peraturan perundang-undangan yang berlaku
                  terkait data pribadi.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Permohonan penghapusan sebagaimana dimaksud pada poin 1 akan
                  Kami proses apabila Anda tidak memilliki kewajiban yang belum
                  terselesaikan terhadap kami, sehingga kami berhak menolak
                  permohonan untuk menghapus Informasi data Pribadi Anda.
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Kami dapat menyimpan salinan arsip data Anda sebagaimana
                  diwajibkan oleh undang-undang atau yang diperlukan untuk
                  melindungi hak-hak kami atau penggunanya
                </li>
              </ol>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                PERUBAHAN DALAM KEBIJAKAN PRIVASI
              </div>
              <div className="text-primary text-size-sub-body-2">
                Kami dapat memperbaharui Kebijakan Privasi ini dari waktu ke
                waktu sebagai bagian dari kepatuhan terhadap ketentuan yang
                berlaku. Setiap perubahan akan kami informasikan kepada Anda.
              </div>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                PERUBAHAN INFORMASI DATA PRIBADI
              </div>
              <ol className="sub-list lh-100">
                <li className="text-primary text-size-sub-body-2">
                  Setiap Informasi Pribadi yang anda berikan kepada Kami, Anda
                  berkewajiban untuk menjaga kebenaran informasi pribadi anda,
                  dan Kami berhak melakukan Tindakan yang dipandang perlu untuk
                  memastikan keakurasian Informasi Pribadi anda termasuk dengan
                  menghubungi Anda;
                </li>
                <li className="text-primary text-size-sub-body-2">
                  Anda dapat melakukan perubahan data pribadi anda melaui
                  website kami atau menghubungi langsung CS Kami.
                </li>
              </ol>
            </div>
          </li>
          <li className="pb-4 mt-4 border-bottom">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                PERNYATAAN DAN PERSETUJUAN
              </div>
              <div className="text-primary text-size-sub-body-2">
                Dengan menggunakan Layanan Kami, Anda mengakui, menjamin,
                memastikan, danmenyatakan bahwa Anda telah membaca, memahami,
                dan menyetujui KebijakanPrivasi ini dan Syarat dan Ketentuan
                Umum dari penggunaan Layanan, serta setuju dan sepakat terhadap
                pengumpulan, penggunaan, penyimpanan, pengolahan, pengalihan dan
                pengungkapan Informasi Pribadi Anda oleh Kami sebagaimana
                dinyatakan di dalam Kebijakan Privasi ini. Anda dapat menarik
                persetujuan Anda untuk setiap atau segala pengumpulan,
                penggunaan atau pengungkapan Informasi Pribadi Anda kapan saja
                dengan memberikan kepada Kami pemberitahuan yang wajar secara
                tertulis melalui rincian kontak yang disebutkan secara khusus
                dibagian lain dari Kebijakan Privasi di bawah ini. Tergantung
                pada keadaan dan sifat persetujuan yang Anda tarik, Anda harus
                memahami dan mengakui bahwa setelah penarikan persetujuan
                tersebut, Anda mungkin tidak lagi dapat menggunakan Layanan.
                Penarikan persetujuan Anda dapat mengakibatkan penghentian akun
                Anda atau hubungan kontraktual Anda dengan Kami, dengan semua
                hak dan kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah
                menerima pemberitahuan untuk menarik persetujuan untuk
                pengumpulan, penggunaan atau pengungkapan Informasi Pribadi
                Anda, Kami akan menginformasikan Anda tentang konsekuensi yang
                mungkin terjadi dari penarikan tersebut sehingga Anda dapat
                memutuskan apakah Anda tetap ingin menarik persetujuan.
              </div>
            </div>
          </li>
          <li className="pb-4 mt-4">
            <div>
              <div className="text-primary fw-extra-bold text-size-sub-headline-2">
                HUBUNGI KAMI
              </div>
              <div className="text-primary text-size-sub-body-2">
                Jika Anda memiliki pertanyaan dan atau keluhan tentang Kebijakan
                Privasi ini atau cara kami menangani informasi pribadi
                Anda,silahkan hubungi kami di:
                <div>
                  <span className="fw-bold">Email :</span> {CONTACTS.email}
                </div>
                <div>
                  <span className="fw-bold">Telepon :</span>{" "}
                  {CONTACTS.generalSupport}
                </div>
              </div>
            </div>
          </li>
        </ol>
      </Container>
    </React.Fragment>
  );
}

export default PrivacyContent;

import React from "react";
import ProductBanner from "./components/product-banner";
import { PRODUCTS } from "../../constants/products";
import ProductBenefit, {
  ProductBenefitContent,
} from "./components/product-benefit";
import formIcon from "../../assets/images/products/form.png";
import timeIcon from "../../assets/images/products/time.png";
import moneyCircleIcon from "../../assets/images/products/money-circle.png";
import secureIcon from "../../assets/images/products/secure.png";
import { Col, Container, Row } from "react-bootstrap";
import ProductClosingStatement from "./components/product-closing-statement";
import { ELEFIN_SUPPLIER_REGISTRATION } from "../../constants";
import PoFinancingPartner from "./components/po-financing-partner";


const BUYER_CONTENTS: ProductBenefitContent[] = [
  {
    title: "Pendaftaran Mudah",
    image: formIcon,
    description:
      "Proses pendaftaran dapat dilakukan secara bersamaan dengan pembelian barang.",
  },
  {
    title: "Tenor dan Cicilan Fleksibel",
    image: moneyCircleIcon,
    description: `Tenor 90 hari tanpa terkena biaya dan bunga apapun. Lebih dari itu, akan terkena bunga 1,5% per bulan.`,
  },
];
const SUPPLIER_CONTENTS: ProductBenefitContent[] = [
  {
    title: "Pencairan Cepat",
    image: timeIcon,
    description:
      "Dana masuk ke rekening anda dalam 2 hari setelah barang diterima",
  },
  {
    title: "Aman dan Terpercaya ",
    image: secureIcon,
    description: `Lebih dari 1.000 supplier telah merasakan keuntungan berpartner dengan Pohon Dana`,
  },
];

function PoFinancing() {
  const product = PRODUCTS.poFinancing;
  return (
    <div className="main-container" id="product-page">
      <ProductBanner product={product} />
      <Container className="my-5">
        <Row className="justify-content-center">
          <Col xl={6} lg={11} md={11} sm={12}>
            <ProductBenefit
              title={
                <React.Fragment>
                  Keuntungan <span className="text-primary-main">Buyer</span>
                </React.Fragment>
              }
              contents={BUYER_CONTENTS}
            />
          </Col>
          <Col xl={6} lg={11} md={11} sm={12} className="mt-5 mt-xl-0">
            <ProductBenefit
              title={
                <React.Fragment>
                  Keuntungan <span className="text-primary-main">Supplier</span>
                </React.Fragment>
              }
              contents={SUPPLIER_CONTENTS}
            />
          </Col>
        </Row>
        <div className="mt-4 text-secondary text-size-label text-center">
          Biaya Keterlambatan - 1,5% per bulan (pro rata)
        </div>
        <PoFinancingPartner/>
        <ProductClosingStatement
          caption="Butuh barang cepat dengan pembayaran diakhir?"
          title="Gunakan PO Financing Sekarang!"
          product={product}
          buttonText="Daftar Sekarang"
          additionalInfo={
            <React.Fragment>
              Ingin menjadi Supplier?{" "}
              <a
                href={ELEFIN_SUPPLIER_REGISTRATION}
                target="_blank"
                rel="noreferrer"
              >
                Daftar disini!
              </a>
            </React.Fragment>
          }
        />
      </Container>
    </div>
  );
}

export default PoFinancing;

import React, { useState } from "react";
import { Card, Col, Container, Image, Row, Stack } from "react-bootstrap";
import Slider from "rc-slider";
import heroBackground from "../../../assets/images/hero-background-reverse-single-round.png";
import calculatorImg from "../../../assets/images/products/calculator.png";
import { calculateInstallment } from "../../../helpers";
import { EMPLOYEE_LOAN_INFO } from "../../../constants/products";

const MAX_AMOUNT = 100000000;
const MIN_AMOUNT = 1000000;
const STEP_AMOUNT = 1000000;
const MIN_TENOR = 1;
const MAX_TENOR = 12;

function ProductEmployeeSimulation() {
  const [nominal, setNominal] = useState<number>(10000000);
  const [tenor, setTenor] = useState<number>(3);
  const [interest, setInterest] = useState<number>(1.8);

  const installmentInfo = calculateInstallment(nominal, interest, tenor);
  return (
    <div
      className="hero-background-reverse-container my-5 pb-5 text-white"
      style={{
        backgroundImage: `url(${heroBackground})`,
      }}
    >
      <Container>
        <div className="d-block d-lg-none mb-2">
          <div className="text-size-title-2 text-center text-primary">
            Simulasi Pinjaman
          </div>
          <div className="text-size-body-head-2 text-center">
            Tentukan nominal dan tenor pinjaman
          </div>
        </div>
        <Row className="align-items-center">
          <Col xl={6} lg={8} md={12}>
            <Card className="bg-extra-color-main-06 py-2 py-sm-4 py-xl-5 px-2 px-sm-4">
              <Card.Body>
                <Stack gap={4} className="mb-4">
                  <Stack gap={2} direction="horizontal">
                    <div className="text-primary text-size-body-head-2">
                      Nominal
                    </div>
                    <div className="text-secondary-dark text-size-body-head ms-auto fw-bold">
                      Rp {Intl.NumberFormat("id-ID").format(nominal)}
                    </div>
                  </Stack>
                  <div>
                    <Slider
                      max={MAX_AMOUNT}
                      min={MIN_AMOUNT}
                      step={STEP_AMOUNT}
                      value={nominal}
                      onChange={(value) => {
                        if (Array.isArray(value)) {
                          value = value[0];
                        }
                        setNominal(value);
                      }}
                    />
                    <Stack gap={2} direction="horizontal" className="mt-2">
                      <div className="text-primary text-size-sub-body-2">
                        Rp {Intl.NumberFormat("id-ID").format(MIN_AMOUNT)}
                      </div>
                      <div className="text-primary text-size-sub-body-2 ms-auto">
                        Rp {Intl.NumberFormat("id-ID").format(MAX_AMOUNT)}
                      </div>
                    </Stack>
                  </div>
                </Stack>
                <Stack gap={4} className="mb-4">
                  <Stack gap={2} direction="horizontal">
                    <div className="text-primary text-size-body-head-2">
                      Tenor
                    </div>
                    <div className="text-secondary-dark text-size-body-head ms-auto fw-bold">
                      {Intl.NumberFormat("id-ID").format(tenor)} bulan
                    </div>
                  </Stack>
                  <div>
                    <Slider
                      max={MAX_TENOR}
                      min={MIN_TENOR}
                      step={1}
                      value={tenor}
                      onChange={(value) => {
                        if (Array.isArray(value)) {
                          value = value[0];
                        }
                        setTenor(value);
                      }}
                    />
                    <Stack gap={2} direction="horizontal" className="mt-2">
                      <div className="text-primary text-size-sub-body-2">
                        {Intl.NumberFormat("id-ID").format(MIN_TENOR)} bulan
                      </div>
                      <div className="text-primary text-size-sub-body-2 ms-auto">
                        {Intl.NumberFormat("id-ID").format(MAX_TENOR)} bulan
                      </div>
                    </Stack>
                  </div>
                </Stack>
                <Stack gap={4} className="mb-4">
                  <Stack gap={2} direction="horizontal">
                    <div className="text-primary text-size-body-head-2">
                      Bunga
                    </div>
                    <div className="text-secondary-dark text-size-body-head ms-auto fw-bold">
                      {Intl.NumberFormat("id-ID").format(interest)}%
                    </div>
                  </Stack>
                  <div>
                    <Slider
                      max={EMPLOYEE_LOAN_INFO.interestRate.end}
                      min={EMPLOYEE_LOAN_INFO.interestRate.start}
                      step={0.1}
                      value={interest}
                      onChange={(value) => {
                        if (Array.isArray(value)) {
                          value = value[0];
                        }
                        setInterest(value);
                      }}
                    />
                    <Stack gap={2} direction="horizontal" className="mt-2">
                      <div className="text-primary text-size-sub-body-2">
                        {Intl.NumberFormat("id-ID").format(
                          EMPLOYEE_LOAN_INFO.interestRate.start
                        )}
                        %
                      </div>
                      <div className="text-primary text-size-sub-body-2 ms-auto">
                        {Intl.NumberFormat("id-ID").format(
                          EMPLOYEE_LOAN_INFO.interestRate.end
                        )}
                        %
                      </div>
                    </Stack>
                  </div>
                </Stack>
                <div className="mt-5">
                  <div className="text-size-sub-body-2 text-primary mb-2">
                    Cicilan per bulan
                  </div>
                  <div className="text-size-sub-headline fw-extra-bold text-secondary-dark mb-4">
                    Rp{" "}
                    {Intl.NumberFormat("id-ID").format(
                      installmentInfo.installment
                    )}
                  </div>
                  <div className="text-size-caption text-secondary mb-2">
                    Biaya di awal
                  </div>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div className="text-size-caption text-secondary">
                        Provisi
                      </div>
                      <div className="text-secondary text-size-sub-body-2">
                        Rp 0
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div className="text-size-caption text-secondary text-end">
                        Admin
                      </div>
                      <div className="text-secondary text-size-sub-body-2 text-end">
                        Rp{" "}
                        {Intl.NumberFormat("id-ID").format(
                          EMPLOYEE_LOAN_INFO.adminFee
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Stack gap={2} direction="horizontal" className="mb-0 mt-4">
                    <div className="text-size-caption text-secondary">
                      Jumlah Diterima Peminjam
                    </div>
                    <div className="text-secondary text-size-sub-body-2 ms-auto">
                      Rp{" "}
                      {Intl.NumberFormat("id-ID").format(
                        nominal - EMPLOYEE_LOAN_INFO.adminFee
                      )}
                    </div>
                  </Stack>
                  <Stack gap={2} direction="horizontal" className="mb-0">
                    <div className="text-size-caption text-secondary">
                      Total Pengembalian
                    </div>
                    <div className="text-secondary text-size-sub-body-2 ms-auto">
                      Rp{" "}
                      {Intl.NumberFormat("id-ID").format(
                        installmentInfo.totalReturn
                      )}
                    </div>
                  </Stack>
                  <div className="border rounded p-2 mt-2">
                    <Row>
                      <Col
                        lg={4}
                        md={4}
                        sm={6}
                        xs={6}
                        className="mb-2 mb-lg-0 text-center"
                      >
                        <div className="text-secondary text-size-sub-body-2">
                          Lama Angsuran
                        </div>
                        <div className="text-secondary text-size-sub-body-2">
                          {Intl.NumberFormat("id-ID").format(tenor)} bulan
                        </div>
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={6}
                        xs={6}
                        className="mb-2 mb-lg-0 text-center"
                      >
                        <div className="text-secondary text-size-sub-body-2">
                          Pokok Pinjaman
                        </div>
                        <div className="text-secondary text-size-sub-body-2">
                          Rp {Intl.NumberFormat("id-ID").format(nominal)}
                        </div>
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={6}
                        xs={6}
                        className="mb-2 mb-lg-0 text-center"
                      >
                        <div className="text-secondary text-size-sub-body-2">
                          Bunga Dibayar
                        </div>
                        <div className="text-secondary text-size-sub-body-2">
                          Rp{" "}
                          {Intl.NumberFormat("id-ID").format(
                            installmentInfo.totalReturn - nominal
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={4} md={12} className="d-none d-lg-block">
            <div className="text-size-title-2 text-center">
              Simulasi Pinjaman
            </div>
            <div className="text-size-body-head-2 text-center">
              Tentukan nominal dan tenor pinjaman
            </div>
            <div className="mt-5 text-center">
              <Image src={calculatorImg} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductEmployeeSimulation;

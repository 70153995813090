import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heroBackground from "../../assets/images/hero-background-about.png";
import TncContent from "./tnc-content";

function Tnc() {
  return (
    <div className="main-container" id="tnc-page">
      <div
        className="hero-background-container privacy-banner position-relative"
        style={{
          backgroundImage: `url(${heroBackground})`,
        }}
      >
        <Container>
          <div>
            <Row className="text-white justify-content-between">
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className="h-100 mt-xl-5 pt-lg-5"
              >
                <div>
                  <div>
                    <h1 className="text-size-title-2 fw-extra-bold banner-product-title">
                      Syarat dan Ketentuan
                    </h1>
                    <div className="mt-2 text-size-body-head-2 text-white">
                      Halaman ini berisi tentang Syarat dan Ketentuan dari
                      penggunaan situs dan aplikasi layanan kami oleh anda.
                      Syarat dan Ketentuan ini wajib anda baca secara hati-hati
                      sebelum menggunakan situs dan aplikasi layanan kami.
                      Apabila anda tidak menyetujui Syarat dan Ketentuan ini,
                      harap untuk tidak menggunakan Situs Web dan Aplikasi
                      Layanan kami
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="my-5">
        <TncContent />
      </Container>
    </div>
  );
}

export default Tnc;

import React, { useEffect } from "react";
import { ENV } from "../config/env";

const Redirect: React.FC = () => {
    useEffect(() => {
        let curURL = window.location.href;

        if (curURL.includes("/register")) {
            window.location.href = "https://app.pohondana.id/register/borrower";
        } else {
            if (curURL.startsWith(ENV.URL)) {
                curURL = curURL.replace(ENV.URL, "https://app.pohondana.id");
                window.location.href = curURL;
            } else {
                window.location.href = "https://app.pohondana.id";
            }
            
            // curURL = curURL.replace(ENV.URL, "app.pohondana.id");
            // window.location.href = curURL;

        }
    }, []);

    return null;
};

export default Redirect;

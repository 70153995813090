import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import contactImage from "../../assets/images/not-found.png";

function Page404() {
  const navigate = useNavigate();
  return (
    <div className="main-container" id="page-404">
      <Container>
        <div>
          <Row className="justify-content-center">
            <Col xl={6} lg={6} sm={12} className="my-5">
              <div className="text-size-title-2 text-primary-main mb-4">
                404
              </div>

              <div className="text-size-body-head-2 fw-bold f mb-4">
                Halaman Tidak Ditemukan
              </div>
              <div className="text-size-sub-body-2 mb-4">
                Halaman yang anda cari tidak tersedia. Silahkan kembali ke
                halaman utama
              </div>
              <Button
                variant="primary"
                onClick={() => {
                  navigate("/");
                }}
              >
                Kembali ke Halaman Utama
              </Button>
            </Col>
            <Col
              xl={6}
              lg={6}
              className="d-none d-lg-flex justify-content-center  pt-lg-5"
            >
              <Image src={contactImage} fluid />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Page404;

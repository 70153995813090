import React from "react";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router";
import { NAVIGATIONS } from "../..";
import Marquee from "react-fast-marquee";

function HomePartner() {
  const imagesRow1 = [];
  const imagesRow2 = [];
  for (let i = 1; i <= 18; i++) {
    let number = i.toString();
    if (i < 10) number = "0" + number;
    imagesRow1.push(require(`../../assets/images/partner-home/${number}.png`));
    const j = i + 18;
    number = j.toString();
    if (j < 10) number = "0" + number;
    imagesRow2.push(require(`../../assets/images/partner-home/${number}.png`));
  }
  return (
    <div className="my-5 py-5">
      <Container>
        <div className="text-size-title-2 fw-extra-bold mb-4 text-center">
          <span className="text-primary-main">Bekerjasama</span> dengan
          Perusahaan Ternama
        </div>
      </Container>
      <div className="mb-5">
        <div className="mb-2 d-flex align-items-center">
          <Marquee play speed={120} direction="right">
            {imagesRow1.map((a, idx) => {
              return (
                <div key={`partnerrow1-${idx}`} className="px-4">
                  <Image src={a} />
                </div>
              );
            })}
          </Marquee>
        </div>
        <div className="d-flex align-items-center">
          <Marquee play delay={1} speed={100} direction="right">
            {imagesRow2.map((a, idx) => {
              return (
                <div key={`partnerrow1-${idx}`} className="px-4">
                  <Image src={a} />
                </div>
              );
            })}
          </Marquee>
        </div>
      </div>
      <Container>
        <div className="mb-4 text-center text-size-sub-body-2">
          <Link to={NAVIGATIONS.partner}>Tampilkan Semua</Link>
        </div>
      </Container>
    </div>
  );
}

export default HomePartner;

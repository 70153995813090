import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import heroBackground from "../../assets/images/hero-background-about.png";
import GeneralClosing from "../../components/general-closing";
import AboutTeam from "./about-team";
import AboutFinancial from "./about-financial";

function About() {
  return (
    <div className="main-container" id="about-page">
      <div
        className="hero-background-container about-banner position-relative"
        style={{
          backgroundImage: `url(${heroBackground})`,
        }}
      >
        <Container>
          <Row className="text-white justify-content-between">
            <Col sm={12} className="h-100  mt-xl-5 pt-xl-5">
              <div>
                <h1 className="text-size-title-2 fw-extra-bold banner-about-title text-white">
                  Pohon Dana
                </h1>
                <div className="mt-2 text-size-body-head-2 text-white">
                  Pohon Dana senantiasa membantu konsumen kami untuk tumbuh
                  bersama dengan memanfaatkan teknologi modern yang mempermudah
                  akses layanan finansial.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mb-5 py-5">
        <div className="mb-5">
          <AboutTeam />
        </div>
        <div className="mb-5 py-5">
          <AboutFinancial />
        </div>

        <GeneralClosing
          title="Tumbuh dan Berkembang Bersama Pohon Dana Sekarang!"
          buttonText="Telusuri Produk Kami"
        />
      </Container>
    </div>
  );
}

export default About;

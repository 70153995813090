import React, { ReactElement, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ProductInfo, PRODUCTS } from "../../../constants/products";
import WorkingCapitalRegistrationModal from "../../../components/products/working-capital-registration-modal";
import ProductCard from "../../../components/products/product-card";

interface IProps {
  caption: string;
  title: string;
  buttonText: string;
  product: ProductInfo;
  additionalInfo?: ReactElement;
}
function ProductClosingStatement({
  caption,
  title,
  buttonText,
  product,
  additionalInfo,
}: IProps) {
  const [showRegistrationCapitalLoan, setRegisterCapitalLoan] = useState(false);
  const handleClick = () => {
    if (product.link === "") return;
    if (product.key === "capitalLoan") {
      setRegisterCapitalLoan(true);
      return;
    }
    window.open(product.link, "_blank");
  };

  return (
    <React.Fragment>
      {caption !== "employee-loan" ? (
        <div className="my-5 py-4 py-lg-5">
          <Row className="justify-content-center">
            <Col xl={10} lg={10} md={10} sm={12}>
              <div className="mb-2 text-size-body-head text-secondary text-center">
                {caption}
              </div>
              <div className="mb-5 text-size-title-2 fw-extra-bold text-primary-main text-center">
                {title}
              </div>
              <div className="text-center">
                <Button variant="primary" size="lg" onClick={handleClick}>
                  {buttonText}
                </Button>
              </div>
            </Col>
          </Row>

          {showRegistrationCapitalLoan && (
            <WorkingCapitalRegistrationModal
              onHide={() => setRegisterCapitalLoan(false)}
            />
          )}
          {additionalInfo ? (
            <div className="mt-4 text-secondary text-center text-size-sub-body-2">
              {additionalInfo}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="my-3 py-3 py-lg-3"></div>
      )}
      <div className="mb-5 mt-2 pb-5">
        <Row className="justify-content-center">
          <h2 className="text-size-sub-headline-2 text-secondary mb-5 text-center">
            Telusuri Produk Lainnya
          </h2>
          {Object.values(PRODUCTS)
            .filter((p) => p.key !== product.key)
            .map((p) => {
              return (
                <Col
                  key={`more-product-${p.key}`}
                  lg={4}
                  md={6}
                  sm={12}
                  className="mt-4"
                >
                  <ProductCard product={p} short onClickCard={() => null} />
                </Col>
              );
            })}
        </Row>
      </div>
    </React.Fragment>
  );
}

export default ProductClosingStatement;

import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import percentageImg from "../../assets/images/percentage-symbol.png";
import profitImg from "../../assets/images/profit-image.png";
import timeImg from "../../assets/images/time-image.png";

const CONTENTS = [
  {
    title: "Bunga rendah & transparan",
    description:
      "Bunga rendah mulai dari 1% per bulan dan biaya yang transparan",
    image: percentageImg,
  },
  {
    title: "Proses Cepat, Tenor Fleksibel",
    description:
      "Pencairan dana hanya dalam 2 jam* dengan pilihan tenor hingga 12 bulan",
    image: timeImg,
  },
  {
    title: "Limit Tinggi",
    description: "Dapatkan limit pinjaman hingga 2 milyar rupiah",
    image: profitImg,
  },
];
function HomeLayanan() {
  return (
    <div className="my-4 py-4">
      <Container>
        <Row className="justify-content-center">
          <Col lg={5} md={10} sm={12}>
            <div className="text-size-title-2 fw-extra-bold mb-4 mb-lg-0 text-center text-lg-start">
              Layanan{" "}
              <span className="text-primary-main">Finansial Terbaik</span> untuk
              B2B
            </div>
          </Col>
          <Col lg={7} md={10} sm={12}>
            {CONTENTS.map((c, cIdx) => {
              return (
                <div
                  key={`content-${cIdx}`}
                  className="mb-2 d-flex align-items-center gap-4"
                >
                  <div>
                    <Image src={c.image} />
                  </div>
                  <div>
                    <div className="fw-extra-bold text-size-body-head text-secondary-dark">
                      {c.title}
                    </div>
                    <div className="text-size-sub-body-2">{c.description}</div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeLayanan;

import React, { useState } from "react";
import ProductBanner from "./components/product-banner";
import { INVOICE_FINANCING_INFO, PRODUCTS } from "../../constants/products";
import ProductBenefit, {
  ProductBenefitContent,
} from "./components/product-benefit";
import lowInterestIcon from "../../assets/images/products/low-interest.png";
import instantPayIcon from "../../assets/images/products/instant-pay.png";
import transparantIcon from "../../assets/images/products/transparancy.png";
import { Container, Form, Button, Spinner } from "react-bootstrap";
import ProductClosingStatement from "./components/product-closing-statement";
import ProductHow, { ProductHowContent } from "./components/product-how";
import ProductPartner from "./components/product-partner";
import { FaSearch, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { ENV } from "../../config/env";
import axios from "axios";

const CONTENTS: ProductBenefitContent[] = [
  {
    title: "Bebas Biaya",
    image: transparantIcon,
    description: `Tidak ada biaya administrasi dan provisi di awal.`,
  },
  {
    title: "Pencairan Cepat",
    image: instantPayIcon,
    description:
      "Dana diterima dalam waktu maksimal 3 jam setelah invoice diterima.",
  },
  {
    title: "Bunga Rendah",
    image: lowInterestIcon,
    description: `Bunga antara ${Intl.NumberFormat("id-ID").format(INVOICE_FINANCING_INFO.interestRate.start)} - ${Intl.NumberFormat("id-ID").format(INVOICE_FINANCING_INFO.interestRate.end)}% per bulan, dengan tenor maksimal 12 bulan.`,
  },
];
const partner = {
  title1: "Perusahaan yang menggunakan",
  title2: "Invoice Financing",
  titleDescription: "Daftar pengguna Pohon Dana",
  product: "invoice-financing",
  description:
    "Invoice kepada pengguna Pohon Dana dapat mempercepat proses approval pinjaman",
};

function InvoiceFinancing() {
  const product = PRODUCTS.invoiceFinancing;
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [searchMessage, setSearchMessage] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const submitForm = async () => {
    try {
      setIsLoading(true);
      let payload = {
        search_query: searchQuery,
      };
      let response = await axios.post(
        `${ENV.API_URL}/pohondana/partner/check-partner`,
        payload
      );
      if (response.data.code === 200) {
        if (response.data.content) {
          setSearchMessage(
            "Perusahaan ini telah bergabung pada ekosistem Pohon Dana. Pengajuan Invoice Financing anda akan lebih mudah disetujui"
          );
        } else {
          setSearchMessage(
            "Perusahaan ini tidak ditemukan pada ekosistem Pohon Dana. Anda masih bisa mengajukan Invoice Financing pada Pohon Dana "
          );
        }
        setIsSearch(true);
        setSearchResult(response.data.content);
      } else {
      }
      setIsLoading(false);
    } catch (exception) {
      console.log(exception);
    }
  };
  const CONTENT_HOW: ProductHowContent[] = [
    {
      title: "Pendaftaran",
      description: (
        <React.Fragment>
          Pendaftaran mandiri melalui{" "}
          <a href={product.link} target="_blank" rel="noreferrer">
            website Pohon Dana
          </a>{" "}
          dengan menyertakan invoice dan legalitas perusahaan.
        </React.Fragment>
      ),
    },
    {
      title: "Verifikasi",
      description: (
        <React.Fragment>
          Tim Pohon Dana memverifikasi invoice dan data peminjam dan
          mempertimbangkan kemampuan bayar.
        </React.Fragment>
      ),
    },
    {
      title: "Persetujuan",
      description: (
        <React.Fragment>
          Tim Pohon Dana menginformasikan nominal yang akan dicairkan dan tenor
          yang disetujui. Peminjam berhak menyetujui atau menolak tawaran yang
          diberikan.
        </React.Fragment>
      ),
    },
    {
      title: "Proses Pencairan",
      description: (
        <React.Fragment>
          Pinjaman diteruskan ke pihak pemberi pinjaman untuk dilakukan proses
          pengiriman dana ke rekening peminjam. Proses ini memakan waktu sekitar
          2 jam.
        </React.Fragment>
      ),
    },
  ];

  return (
    <div className="main-container" id="product-page">
      <ProductBanner product={product} />
      <Container className="my-5">
        <ProductBenefit
          title={
            <React.Fragment>
              <span className="text-primary-main">Keuntungan</span>{" "}
              {product.title}
            </React.Fragment>
          }
          contents={CONTENTS}
        />
        <div className="mt-4 text-secondary text-size-label text-center">
          Biaya Keterlambatan - 1,5% per bulan (pro rata)
        </div>

        <div className="mt-5 pt-5">
          <ProductHow product={product} contents={CONTENT_HOW} />
        </div>
        <ProductPartner data={partner} />
        <div className="mt-4 pt-4 " style={{ maxWidth: 700, margin: "0 auto" }}>
          <div className="py-3 text-secondary text-size-sub-body-4 lh-28 text-center">
            Invoice kepada pengguna Pohon Dana dapat mempercepat proses approval
            pinjaman
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <div className="form-search">
              <div className="d-flex justify-content-center align-items-center p-2">
                <FaSearch size={20} />
              </div>
              <div style={{width:"100%"}} className="d-flex justify-content-center align-items-center">
                <Form.Control
                  className="form-input-search"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Cari berdasarkan nama atau NPWP perusahaan"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      submitForm();
                      
                    }
                  }}
                />
              </div>
              <div onClick={()=>{
                setSearchQuery("");
                setIsSearch(false);
              }} className="d-flex justify-content-center align-items-center p-2" >
                <FaTimesCircle style={{
                  cursor:"pointer"
                }} size={20}/>
              </div>
              <div className="d-flex justify-content-center align-items-center p-2" >
                <Button disabled={isLoading} onClick={()=>{
                    submitForm();
                }}>
                   {isLoading ? <Spinner animation="border" size="sm" /> : "Cari"}
                </Button>
              </div>
            </div>
          </Form>
          {isSearch && (
            <div
              style={{ maxWidth: 700, padding: "10px 15px" }}
              className={`${
                searchResult ? "text-primary-main" : " text-error-main"
              } d-flex align-items-center gap-2 `}
            >
              {searchResult ? (
                <FaCheckCircle size={22} />
              ) : (
                <FaTimesCircle size={22} />
              )}

              <span className="text-size-caption">{searchMessage}</span>
            </div>
          )}
        </div>

        <ProductClosingStatement
          caption="Butuh dana instan?"
          title="Ubah Invoice anda di Pohon Dana!"
          product={product}
          buttonText="Ajukan Pinjaman"
        />
      </Container>
    </div>
  );
}

export default InvoiceFinancing;

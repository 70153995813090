import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import fb from "../../assets/images/social-media/fb.png";
import ig from "../../assets/images/social-media/ig.png";
import wa from "../../assets/images/social-media/wa.png";
import line from "../../assets/images/social-media/line.png";
import tele from "../../assets/images/social-media/tele.png";
import tiktok from "../../assets/images/social-media/tiktok.png";
import x from "../../assets/images/social-media/x.png";

function HomeWarning() {
  return (
    <div className="my-5 pb-5">
      <Container>
        <div className="border border-primary-main rounded-4 p-4 border-2">
          <Row className="justify-content-center align-items-center">
            <Col xl={6} lg={6} md={10} sm={12} className="text-center">
              <div className="text-size-title-2 fw-extra-bold text-danger">
                Waspada Penipuan
              </div>
              <div className="text-size-body-head">atas nama Pohon Dana</div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={10}
              sm={12}
              className="mt-4 mt-lg-0 text-center"
            >
              <div className="text-size-body-head-2 mb-4 mb-md-2">
                Pohon Dana tidak pernah menawarkan Layanan Peminjaman Dana
                melalui media sosial!
              </div>
              <div className="d-block d-md-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="px-2">
                    <Image src={fb} fluid />
                  </div>
                  <div className="px-2">
                    <Image src={ig} fluid />
                  </div>
                  <div className="px-2">
                    <Image src={wa} fluid />
                  </div>
                  <div className="px-2">
                    <Image src={tele} fluid />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="px-2">
                    <Image src={line} fluid />
                  </div>
                  <div className="px-2">
                    <Image src={tiktok} fluid />
                  </div>
                  <div className="px-2">
                    <Image src={x} fluid />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default HomeWarning;

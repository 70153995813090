const stage = process.env.REACT_APP_ENV || 'production'

let environment
switch (stage) {
  case 'production':
    environment = {
      STAGE: stage,
      SENTRY_DSN: '',
      GA_MEASUREMENT_ID: '',
      API: {
        TIMEOUT: 60000,
        HOST: '',
      },
      URL: 'https://www.pohondana.id',
      LOGIN_URL: 'https://app.pohondana.id/login',
      API_URL: 'https://apiv2.pohondana.id',
      pohon_dana_url: 'https://app.pohondana.id'
    }
    break

  case 'staging':
    environment = {
      STAGE: stage,
      SENTRY_DSN: '',
      GA_MEASUREMENT_ID: '',
      API: {
        TIMEOUT: 60000,
        HOST: '',
      },
      URL: '',
      LOGIN_URL: 'https://staging.pohondana.co.id/login',
      API_URL: 'https://api.staging.pohondana.co.id',
      pohon_dana_url: 'https://staging.pohondana.co.id'
    }
    break

  case 'test':
  case 'development':
  default:
    environment = {
      STAGE: stage,
      SENTRY_DSN: '',
      GA_MEASUREMENT_ID: '',
      API: {
        TIMEOUT: 60000,
        HOST: '',
      },
      URL: 'http://localhost:3000',
      LOGIN_URL: 'https://dev.pohondana.id/login',
      API_URL: 'https://api-dev.pohondana.id',
      pohon_dana_url: 'https://dev.pohondana.id'
    }
    break
}

export const ENV = environment

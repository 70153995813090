import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { PRODUCTS } from "../../constants/products";
import ProductCard from "./product-card";
import { ENV } from "../../config/env";
import WorkingCapitalRegistrationForm from "./working-capital-registration-form";
import WorkingCapitalError from "./working-capital-error";

interface IProps {
  onHide: () => void;
}

function ProductModal({ onHide }: IProps) {
  const [registerCapitalLoan, setRegisterCapitalLoan] = useState(false);
  const [failedCapitalLoan, setFailedCapitalLoan] = useState(false);

  const handleHide = () => {
    if (failedCapitalLoan) {
      onHide();
      return;
    }
    if (registerCapitalLoan) {
      setRegisterCapitalLoan(false);
      return;
    }
    onHide();
  };

  return (
    <React.Fragment>
      <Modal
        show
        onHide={handleHide}
        size={registerCapitalLoan ? (failedCapitalLoan ? "xl" : "lg") : "xl"}
      >
        <Modal.Header closeButton>
          {failedCapitalLoan ? (
            <Modal.Title className="text-center fw-bold text-danger">
              Registrasi anda tidak dapat dilanjutkan!
            </Modal.Title>
          ) : (
            <Modal.Title
              className={
                registerCapitalLoan ? "text-center fw-bold" : "text-center"
              }
            >
              {registerCapitalLoan
                ? `Registrasi Working Capital Loan`
                : "Pilih jenis pinjaman yang sesuai"}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col lg={10} md={12}>
              {failedCapitalLoan ? (
                <WorkingCapitalError onClose={onHide} />
              ) : registerCapitalLoan ? (
                <WorkingCapitalRegistrationForm
                  onCancel={handleHide}
                  onFinish={onHide}
                  onFailed={() => setFailedCapitalLoan(true)}
                />
              ) : (
                <React.Fragment>
                  <Row>
                    {Object.keys(PRODUCTS).map((p) => {
                      const productInfo = PRODUCTS[p];
                      return (
                        <Col
                          key={p}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="p-2"
                        >
                          <ProductCard
                            product={productInfo}
                            onRegisterCapitalLoan={() => {
                              setRegisterCapitalLoan(true);
                            }}
                            onNavigate={onHide}
                         
                          />
                        
                        </Col>
                      );
                    })}
                  </Row>
                  <div className="mt-5 text-center text-size-sub-body-2">
                    Sudah punya akun?{" "}
                    <a href={ENV.LOGIN_URL} target="_blank" rel="noreferrer">
                      Masuk di sini
                    </a>
                  </div>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ProductModal;

import React, { useState } from "react";
import ProductBanner from "./components/product-banner";
import { PRODUCTS, WORKING_CAPITAL_LOAN_INFO } from "../../constants/products";
import ProductBenefit, {
  ProductBenefitContent,
} from "./components/product-benefit";
import lowInterestIcon from "../../assets/images/products/low-interest.png";
import moneyCircleIcon from "../../assets/images/products/money-circle.png";
import transparantIcon from "../../assets/images/products/transparancy.png";
import { Container } from "react-bootstrap";
import ProductClosingStatement from "./components/product-closing-statement";
import ProductInterest from "./components/product-interest";
import ProductHow, { ProductHowContent } from "./components/product-how";
import WorkingCapitalRegistrationModal from "../../components/products/working-capital-registration-modal";
import ProductPartner from "./components/product-partner";

const CONTENTS: ProductBenefitContent[] = [
  {
    title: "Bunga Rendah",
    image: lowInterestIcon,
    description: `Bunga antara ${Intl.NumberFormat("id-ID").format(WORKING_CAPITAL_LOAN_INFO.interestRate.start)} - ${Intl.NumberFormat("id-ID").format(WORKING_CAPITAL_LOAN_INFO.interestRate.end)}% per bulan, dengan tenor maksimal 12 bulan.`,
  },
  {
    title: "Limit Tinggi",
    image: moneyCircleIcon,
    description:
      "Pinjaman maksimal 2 milyar rupiah yang disesuaikan dengan credit score dan penggunakan dana.",
  },
  {
    title: "Biaya Transparan",
    image: transparantIcon,
    description: `Mulai dari 1% per transaksi yang akan diinformasikan sebelum proses pencairan dana.`,
  },
];

function WorkingCapitalLoan() {
  const product = PRODUCTS.capitalLoan;
  const [registerCapitalLoan, setRegisterCapitalLoan] = useState(false);

  const partner = {
    title1: "Perusahaan yang menggunakan",
    title2: "Working Capital Loan",
    titleDescription: "",
    product: "working-capital-loan",
    description: "",
  };

  const CONTENT_HOW: ProductHowContent[] = [
    {
      title: "Pendaftaran",
      description: (
        <React.Fragment>
          Pendaftaran mandiri melalui{" "}
          <a
            href="/"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setRegisterCapitalLoan(true);
            }}
          >
            website Pohon Dana
          </a>{" "}
          dengan menyertakan legalitas perusahaan.
        </React.Fragment>
      ),
    },
    {
      title: "Verifikasi",
      description: (
        <React.Fragment>
          Tim Pohon Dana memverifikasi data peminjam dan mempertimbangkan
          kemampuan bayar.
        </React.Fragment>
      ),
    },
    {
      title: "Persetujuan",
      description: (
        <React.Fragment>
          Tim Pohon Dana menginformasikan nominal yang akan dicairkan, tenor
          yang disetujui, dan biaya transaksi. Peminjam berhak menyetujui atau
          menolak tawaran yang diberikan.
        </React.Fragment>
      ),
    },
    {
      title: "Proses Pencairan",
      description: (
        <React.Fragment>
          Pinjaman diteruskan ke pihak pemberi pinjaman untuk dilakukan proses
          pengiriman dana ke rekening peminjam. Proses ini memakan waktu sekitar
          2 jam.
        </React.Fragment>
      ),
    },
  ];

  return (
    <div className="main-container" id="product-page">
      <ProductBanner product={product} />
      <Container className="my-5">
        <ProductBenefit
          title={
            <React.Fragment>
              <span className="text-primary-main">Keuntungan</span>{" "}
              {product.title}
            </React.Fragment>
          }
          contents={CONTENTS}
        />
      </Container>
      <ProductInterest
        position="right"
        title={
          <React.Fragment>
            <span className="text-primary-main">Biaya</span> Pinjaman untuk{" "}
            <span className="text-primary-main">Modal Kerja</span>
          </React.Fragment>
        }
        interestMin={WORKING_CAPITAL_LOAN_INFO.interestRate.start}
        interestMax={WORKING_CAPITAL_LOAN_INFO.interestRate.end}
        caption={
          <React.Fragment>
            <div>Biaya Pinjaman - mulai dari 1% per transaksi</div>
            <div>Biaya Keterlambatan - 1,5% per bulan (pro rata)</div>
          </React.Fragment>
        }
      />
      <Container className="mt-5 pt-5">
        <ProductHow product={product} contents={CONTENT_HOW} />
      </Container>
      <ProductPartner data={partner} />

      <Container className="my-5">
        <ProductClosingStatement
          caption="Butuh modal tambahan untuk bisnis anda?"
          title="Ajukan Pinjaman Dana di Pohon Dana!"
          product={product}
          buttonText="Ajukan Pinjaman"
        />
      </Container>
      {registerCapitalLoan && (
        <WorkingCapitalRegistrationModal
          onHide={() => setRegisterCapitalLoan(false)}
        />
      )}
    </div>
  );
}

export default WorkingCapitalLoan;

import React from "react";
import HomeBanner from "./home-banner";
import HomeProduct from "./home-product";
import HomeLayanan from "./home-layanan";
import HomePerkembangan from "./home-perkembangan";
import HomePartner from "./home-partner";
import HomeWarning from "./home-warning";
import GeneralClosing from "../../components/general-closing";
import { Container } from "react-bootstrap";

function Home() {
  return (
    <div className="main-container" id="home-page">
      <HomeBanner />
      <HomeProduct />
      <HomeLayanan />
      <HomePerkembangan />
      <HomePartner />
      <Container>
        <GeneralClosing
          title="Gabung dan Tumbuh Bersama Pohon Dana Sekarang!"
          caption="Siap menjadi bagian dari kami?"
          buttonText="Gabung Sekarang!"
        />
      </Container>
      <HomeWarning />
    </div>
  );
}

export default Home;

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import heroBackground from "../../assets/images/hero-background-about.png";
import complaintHeader from "../../assets/images/complaint.png";
import ComplaintContent from "./complaint";

function Complaint() {
  return (
    <div className="main-container" id="complaint-page">
      <div
        className="hero-background-container complaint-banner position-relative"
        style={{
          backgroundImage: `url(${heroBackground})`,
        }}
      >
        <Container>
          <div>
            <Row className="text-white justify-content-between">
              <Col
                xl={7}
                lg={6}
                md={12}
                sm={12}
                className="h-100 mt-xl-5 pt-lg-5"
              >
                <div>
                  <div>
                    <h1 className="text-size-title-2 fw-extra-bold banner-product-title">
                      Mekanisme dan Layanan Pengaduan Pengguna
                    </h1>
                  </div>
                </div>
              </Col>
              <Col
                xl={5}
                lg={6}
                className="d-none d-lg-flex justify-content-end text-end pt-lg-5"
                style={{ overflow: "hidden" }}
              >
                <Image src={complaintHeader} fluid />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mb-5 py-5">
        <ComplaintContent />
      </Container>
    </div>
  );
}

export default Complaint;

import React, { useState } from "react";
import { Link } from "react-router";
import {
  Button,
  Carousel,
  Container,
  Image,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { DEFAULT_WEB_TITLE } from "../constants";
import { ENV } from "../config/env";
import { NAVIGATIONS } from "..";
import ProductModal from "./products/product-modal";
import { PRODUCTS } from "../constants/products";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import { FaInfoCircle } from "react-icons/fa";
import { TKB_INFO } from "../constants/tkb";

function Header() {
  const tkb = useSelector((state: RootState) => state.data?.data?.tkb);
  const [showRegistration, setShowRegistration] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [infoTKB, setInfoTKB] = useState(false);
  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        variant="light"
        id="site-header"
        fixed="top"
        className="bg-white border-bottom"
      >
        <Container className="py-2 py-lg-0">
          <Navbar.Brand as={Link} to={NAVIGATIONS.home} className="py-0">
            <Image
              src={`${ENV.URL}/images/logo-black.png`}
              alt=""
              title={DEFAULT_WEB_TITLE}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse
            id="navbarSupportedContent"
            className="justify-content-between"
          >
            <Nav className="top-nav-section align-items-lg-center justify-content-start ms-0 ms-lg-2 ms-xl-5 gap-1 gap-lg-4 gap-xl-5">
              <NavDropdown
                show={openProduct}
                title="Produk"
                className="hide-dropdown-icon text-size-sub-body-2"
                onMouseOver={() => setOpenProduct(true)}
                onMouseLeave={() => setOpenProduct(false)}
                onClick={() => setOpenProduct(!openProduct)}
              >
                {Object.keys(PRODUCTS).map((p) => {
                  const nav = NAVIGATIONS[p] || "";
                  return (
                    <NavDropdown.Item
                      key={p}
                      as={Link}
                      to={nav}
                      className={`text-size-sub-body-2 ${openProduct ? "" : "d-none"}`}
                    >
                      {PRODUCTS[p].title}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
              <Nav.Item>
                <Link to={NAVIGATIONS.about} className="text-size-sub-body-2">
                  Tentang Kami
                </Link>
              </Nav.Item>
              {tkb && tkb.TKB90 && (
                <NavDropdown
                  title={
                    <React.Fragment>
                      <FaInfoCircle
                        className="text-size-caption me-2"
                        style={{ marginTop: -2 }}
                        onClick={() => setInfoTKB(true)}
                      />{" "}
                      TKB90 | {Intl.NumberFormat("id-ID").format(tkb.TKB90)}%
                    </React.Fragment>
                  }
                  className="hover-dropdown dropdown-tkb text-size-caption mt-3 mt-lg-0"
                >
                  {Object.keys(tkb).map((p) => {
                    return (
                      <NavDropdown.Item
                        key={p}
                        as={"div"}
                        className="text-size-caption"
                      >
                        {p} | {Intl.NumberFormat("id-ID").format(tkb[p])}%
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              )}
            </Nav>
            <Nav className="top-nav-section align-items-lg-center justify-content-start ms-0 ms-lg-5 gap-3 gap-lg-5 mt-3 mt-lg-0">
              <Nav.Item>
                <a
                  href={`${ENV.pohon_dana_url}/login`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-size-sub-body-2"
                >
                  Masuk
                </a>
              </Nav.Item>
              <Nav.Item>
                <Button
                  variant="primary"
                  className="white-hover"
                  onClick={() => setShowRegistration(true)}
                >
                  Mulai Pinjaman
                </Button>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showRegistration && (
        <ProductModal onHide={() => setShowRegistration(false)} />
      )}
      {infoTKB && (
        <Modal show onHide={() => setInfoTKB(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pt-0">
            <div className="position-relative w-100">
              <Carousel
                controls
                variant="dark"
                touch
                interval={null}
                className="tkb-carousel"
              >
                {Object.keys(tkb).map((p) => {
                  const info = TKB_INFO[p];
                  if (!info) return null;
                  return (
                    <Carousel.Item key={`carousel-tkb-${p}`}>
                      <div
                        style={{ height: 350, width: "80%" }}
                        className="mx-auto"
                      >
                        <div className="text-size-body-head-2 fw-bold text-center">
                          {p} | {tkb[p]}%
                        </div>
                        <div className="text-center text-size-sub-body-2 my-4">
                          {info.description}
                        </div>
                        <div className="text-center">
                          <Image src={info.formulaImage} fluid />
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default Header;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router";
import { Button, Card, Image } from "react-bootstrap";
import { NAVIGATIONS } from "../../";
// import { ProductInfo } from "../../constants/products";
import WorkingCapitalRegistrationModal from "./working-capital-registration-modal";

interface IProps {
  product: Record<string, any>;
  onRegisterCapitalLoan?: () => void;
  short?: boolean;
  onClickCard?: () => void;
  onNavigate?: () => void;
}

function EmployeeCard({
  product,
  onRegisterCapitalLoan,
  short = false,
  onClickCard,
  onNavigate,
}: IProps) {
  const navigate = useNavigate();
  const [registerCapitalLoan, setRegisterCapitalLoan] = useState(false);

  const handleRegister = () => {
    if (!product) {
      return null;
    }
    if (product.link === "") {
      return null;
    }
    if (product.key === "contact") {
        navigate(NAVIGATIONS[product.key]);
      return;
    }
    window.open(product.link, "_blank");
  };
  const handleClickCard = () => {
    if (onClickCard) {
      navigate(NAVIGATIONS[product.key]);
      onClickCard();
    }
    return;
  };
  const handleClickLink = () => {
    if (onNavigate) {
      onNavigate();
    }
  };

  return (
    <React.Fragment>
      <Card
        className={`h-100 card-hover ${onClickCard ? "cursor-pointer" : ""}`}
        onClick={handleClickCard}
      >
        <Card.Body className="p-0">
          <div className="d-flex h-100 justify-content-between">
            <div className="p-4 d-flex flex-column justify-content-between">
              <div>
                <div className="fw-bold text-size-body-head-2 text-primary-main">
                  {product.title}
                </div>
                {!short && <div>{product.shortDescription}</div>}
              </div>
              {short ? (
                <div className="mt-4 text-size-caption">
                  <Link
                    to={NAVIGATIONS[product.key]}
                    className="text-secondary"
                    onClick={handleClickLink}
                  >
                    Pelajari lebih lanjut
                  </Link>
                </div>
              ) : (
                <div className="mt-4 d-flex d-md-block d-lg-flex align-items-center">
                  <div className="me-4 mb-0 mb-md-2 mb-lg-0">
                    <Button variant="primary" onClick={handleRegister}>
                      {product.buttonText}
                    </Button>
                  </div>
                  
                </div>
              )}
            </div>
            <div className="pe-2 align-self-end">
              <Image src={product.shortImage} fluid />
            </div>
          </div>
        </Card.Body>
      </Card>
      {registerCapitalLoan && (
        <WorkingCapitalRegistrationModal
          onHide={() => setRegisterCapitalLoan(false)}
        />
      )}
    </React.Fragment>
  );
}

export default EmployeeCard;

import tkb0 from "../assets/images/tkb/tkb0.png";
import tkb30 from "../assets/images/tkb/tkb30.png";
import tkb60 from "../assets/images/tkb/tkb60.png";
import tkb90 from "../assets/images/tkb/tkb90.png";

export type TKBInfo = {
  key: string;
  description: string;
  formulaImage: string;
};

export const TKB_INFO: Record<string, TKBInfo> = {
  TKB0: {
    key: "TKB0",
    description:
      "TKB0 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 0 (nol) hari kalender terhitung sejak jatuh tempo;",
    formulaImage: tkb0,
  },
  TKB30: {
    key: "TKB30",
    description:
      "TKB30 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 30 (tiga puluh) hari kalender terhitung sejak jatuh tempo;",
    formulaImage: tkb30,
  },
  TKB60: {
    key: "TKB60",
    description:
      "TKB60 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 60 (enam puluh) harikalender terhitung sejak jatuh tempo;",
    formulaImage: tkb60,
  },
  TKB90: {
    key: "TKB90",
    description:
      "TKB90 adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 90 (sembilan puluh) hari kalender terhitung sejak jatuh tempo;",
    formulaImage: tkb90,
  },
};

import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";

const CONTENT = [
  {
    title: "Definisi",
    content: (
      <React.Fragment>
        <div className="text-primary text-size-sub-body-2 pt-3">
          Untuk tujuan Syarat dan Ketentuan ini, istilah dimanapun dalam Syarat
          & Ketentuan ini akan memiliki arti sebagai berikut :
        </div>
        <ul className="sub-list">
          <li className="text-primary text-size-sub-body-2">
            Penerima Dana adalah orang perseorangan warga negara Indonesia, atau
            badan hukum yang terdaftar di Indonesia yang mengajukan Pendanaan
            pada situs atau aplikasi POHON DANA
          </li>
          <li className="text-primary text-size-sub-body-2">
            Pemberi Dana adalah orang perseorangan, badan hukum, badan usaha
            atau lembaga International sesuai denga ketentuan Peraturan Otoritas
            Jasa Keuangan No.10/POJK.05/2022 yang menempatkan dana pada Rekening
            POHON DANA untuk disalurkan kepada Penerima Dana POHON DANA
          </li>
          <li className="text-primary text-size-sub-body-2">
            Pendanaan adalah sejumlah dana yang ditempatkan oleh Pemberi Dana
            pada Rekening POHON DANA untuk disalurkan sebagai Pendanaan Kepada
            Penerima Dana melalui aplikasi atau situs POHON DANA
          </li>
          <li className="text-primary text-size-sub-body-2">
            Jasa adalah kegiatan operasional POHON DANA pada situs maupun
            aplikasi POHON DANA yang akan memfasilitasi proses pinjam meminjam
            berbasis teknologi informasi
          </li>
          <li className="text-primary text-size-sub-body-2">
            Hari kerja adalah senin-jumat(selain Sabtu, Minggu atau hari libur
            nasional)
          </li>
          <li className="text-primary text-size-sub-body-2">
            Rekening Escrow adalah rekening escrow account yang meliputi virtual
            account atas nama masing-masing Pengguna dan Pohon Dana yang
            disediakan oleh bank yang bekerjasama dengan Pohon Dana, dimana
            Pengguna diwajibkan untuk meletakkan sejumlah dana hanya kepada
            Rekening Escrow Pohon Dana, dan Pohon Dana tidak bertanggung jawab
            terhadap pengiriman dana oleh Pengguna ke rekening diluar Rekening
            Escrow Pohon Dana.
          </li>
          <li className="text-primary text-size-sub-body-2">
            Hukum adalah setiap peraturan, kebijakan, undang-undang, ataupun
            norma yang berlaku dan memiliki kekuatan hukum untuk dijalankan dan
            ditaati bersama
          </li>
          <li className="text-primary text-size-sub-body-2">
            Data Pribadi adalah informasi perseorangan tertentu yang disimpan,
            dirawat, dijaga kebenaran serta dilindungi kerahasiaannya yang dapat
            digunakan untuk mengidentifikasi secara langsung atau tidak langsung
            setiap individu
          </li>
          <li className="text-primary text-size-sub-body-2">
            Tenaga Profesional POHON DANA adalah setiap pejabat dan/atau pegawai
            dari POHON DANA yang telah resmi terdaftar sebagai pejabat
            dan/ataupun karyawan POHON DANA
          </li>
          <li className="text-primary text-size-sub-body-2">
            Syarat dan Ketentuan ini dapat direvisi, ditambah, atau diamandemen
            dari waktu ke waktu, dan perubahan atas Syarat dan Ketentuan ini
            akan ditempatkan pada situs dan aplikasi POHON DANA dengan
            mencantumkan tanggal perubahan terakhir dari Syarat dan Ketentuan
            POHON DANA ini
          </li>
          <li className="text-primary text-size-sub-body-2">
            Dalam Syarat dan Ketentuan ini, semua rujukan kepada perseorangan
            atau badan hukum, adalah juga sebagai merujuk kepada lembaga yang
            sah, penerima hak pengalihan, penerus, ataupun kuasa/wakil dari
            perseorangan atau badan hukum selmama dianggap wajar
          </li>
          <li className="text-primary text-size-sub-body-2">
            Setiap rujukan Hukum, peraturan, undang-undang adalah merujuk pada
            amandemen dan perubahan peraturan terkait, atau peraturan yang
            berada dibawah Hukum, peraturan, atau penetapan tersebut
          </li>
          <li className="text-primary text-size-sub-body-2">
            Tidak ada Hukum (atau interprestasinya) yang menyatakan ambiguitas
            dalam suatu dokumen terkait Syarat dan Ketentuan ini
          </li>
          <li className="text-primary text-size-sub-body-2">
            Tingkat suku bunga yang disajikan adalah tingkat suku bunga per
            bulan
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    title: "Persetujuan",
    content: (
      <React.Fragment>
        <ul className="sub-list">
          <li className="text-primary text-size-sub-body-2">
            Syarat dan Ketentuan ini merupakan syarat-syarat dan
            ketentuan-ketentuan dimana PT. Pohon Dana Indonesia akan memberikan
            jasa dengan pengoperasian situs dan aplikasi POHON DANA yang akan
            memfasilitasi proses pendanaan
          </li>
          <li className="text-primary text-size-sub-body-2">
            Anda menyetujui bahwa POHON DANA mempunyai hak mutlak untuk
            menentukan apakah Anda dapat masuk dalam daftar calon Penerima Dana
            dan Pemberi Dana. Dalam hal Anda tidak masuk dalam daftar calon
            Penerima Dana maupun Pemberi Dana, POHON DANA tidak berhak untuk
            menyediakan Jasa ataupun memunculkan kewajiban tambahan apapun
            kepada Anda sebagai Penerima Dana atau Pemberi Dana
          </li>
          <li className="text-primary text-size-sub-body-2">
            Ketentuan pengguna Jasa dari POHON DANA akan tunduk pada Hukum serta
            Syarat dan Ketentuan ini, dan POHON DANA tidak bertanggung jawab
            atas tindakan yang diambil oleh pihak manapun dalam rangka mematuhi
            Hukum serta Syarat dan Ketentuan ini kecuali memperoleh persetujuan
            terlebih dahulu dari POHON DANA
          </li>
          <li className="text-primary text-size-sub-body-2">
            Kecuali diatur lain, Jasa yang disediakan oleh POHON DANA dapat
            termasuk memfasilitasi Pendanaan, atau jasa lain yang POHON DANA
            akan perkenalkan dari waktu ke waktu
          </li>
          <li className="text-primary text-size-sub-body-2">
            Apabila terdapat perbedaan dan pertentangan antara Syarat dan
            Ketentuan dan perjanjian yang mengikat oleh dan dengan POHON DANA,
            maka peraturan dalam perjanjian yang berlaku
          </li>
          <li className="text-primary text-size-sub-body-2">
            <div>
              Anda memahami, mengakui, dan setuju bahwa POHON DANA tidak dan
              tidak akan pernah: <br />
              <ul className="sub-list2">
                <li>
                  Menjalankan kegiatan perbankan, pasar modal, jasa keuangan
                  non-bank, dan kegiatan lainnya sebagaimana diatur oleh
                  Otoritas Jasa Keuangan (OJK) dan Bank Indonesia (BI)
                </li>
                <li>
                  Menyelenggarakan simpanan dalam bentuk apapun seperti
                  tabungan, deposito, giro atau bentuk lainnya yang dipersamakan
                  dengan itu
                </li>
                <li>
                  Menyediakan jasa peringkat kredit atau bentuk kegiatan lainnya
                  yang dipersamakan dengan itu sebagaimana diatur oleh Otoritas
                  Jasa Keuangan dan Bank Indonesia
                </li>
                <li>Menjalankan pendanaan modal</li>
                <li>
                  Memihak salah satu pihak, baik Peminjam maupun Pemberi
                  Pinjaman dalam perjanjian Pinjaman atau
                </li>
                <li>Menyediakan jasa penyimpanan/penitipan</li>
              </ul>
            </div>
          </li>
          <li className="text-primary text-size-sub-body-2">
            Anda memahami, mengakui, dan setuju bahwa peran POHON DANA hanya
            sebagai fasilitator dan bersifat administrative yang mengatur
            perjanjian antara Penerima Dana dan Pemberi Dana dalam Perjanjian
            Pendanaan terkait, sesuai dengan Peraturan Otoritas Jasa Keuangan
            Nomor 40 tahun 2024 Tentang Layanan Pendanaan Bersama Berbasis
            Teknologi Informasi
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    title: "Pengajuan Pinjaman",
    content: (
      <React.Fragment>
        <ul className="sub-list">
          <li className="text-primary text-size-sub-body-2">
            Calon Penerima Dana akan mengajukan permohonan Pengajuan Pendanaan
            pada situs atau aplikasi POHON DANA dengan cara mengisi aplikasi
            yang tersedia pada situs maupun aplikasi POHON DANA
          </li>
          <li className="text-primary text-size-sub-body-2">
            Setelah menerima aplikasi Pengajuan Pendanaan, penilai kredit dari
            POHON DANA akan mengadakan uji kelayakan calon Penerima Dana. Selama
            proses berlangsung, POHON DANA akan menghubungi lembaga, perusahaan,
            atau individu terkait untuk mencari informasi, melakukan verifikasi,
            dan konfirmasi informasi terkait Penerima Dana, termasuk (namun
            tidak terbatas pada) catatan historis hukum dan kredit. Penerima
            Dana wajib memberikan izin dan kuasa kepada POHON DANA untuk
            melakukan hal-hal tersebut
          </li>
          <li className="text-primary text-size-sub-body-2">
            Setelah hasil yang memadai dari uji kelayakan oleh POHON DANA, POHON
            DANA akan menginformasikan kepada Penerima Dana apakah Pengajuan
            Penerima Dana tersebut disetujui atau tidak, dan menginformasikan
            mengenai Syarat dan Ketentuan yang berlaku pada situs dan aplikasi
            POHON DANA
          </li>
          <li className="text-primary text-size-sub-body-2">
            Setelah Penerima Dana setuju pada Syarat dan Ketentuan Pendanaan
            yang ditawarkan, Penerima Dana harus melengkapi aplikasi Pengajuan
            Pendanaan, yaitu detail lengkap nomor rekening tujuan pencairan, di
            nama pemilik rekening tujuan adalah harus sama dengan nama Penerima
            Dana
          </li>
          <li className="text-primary text-size-sub-body-2">
            Setelah Pengajuan Pendanaan disetujui oleh kedua belah pihak, maka
            Penerima Dana setuju untuk mengikatkan diri dalam perjanjian dan
            dokumen lain yang terkait fasilitas Pendanaan (jika ada) dalam
            jangka waktu yang ditentukan oleh POHON DANA.
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    title: "Pembayaran oleh peminjam",
    content: (
      <React.Fragment>
        <ul className="sub-list">
          <li className="text-primary text-size-sub-body-2">
            Pembayaran kembali Pendanaan, dengan bunga, harus dilakukan secara
            berkala. Ketika Penerima Dana mengalami gagal bayar, POHON DANA akan
            memberikan peringatan dalam bentuk surat teguran tidak dilakukan
            pembayaran oleh Penerima Dana dalam jangka waktu 5 (lima) hari
            kerja, dan peringatan diberikan maksimal 3 (tiga) kali. Apabila
            Penerima Dana gagal membayar kembali angsuran setelah peringatan
            ketiga maka Penerima Dana dinyatakan wanprestasi. Apabila terjadi
            wanprestasi, POHON DANA akan melakukan restrukturisasi atau membuat
            skema pembayaran kembali Pendanaan dan dapat melakukan tindakan
            hukum dalam proses penyelesaian kredit
          </li>
          <li className="text-primary text-size-sub-body-2">
            Penerima Dana memahami dan setuju mematuhi untuk membayar setiap
            layanan fasilitas Pendanaan, biaya lain-lain kepada POHON DANA
            dan/atau pihak terkait lainnya dalam melakukan pengaturan dan
            administratif fasilitas Pendanaan dan Perjanjian terkait. Sebagai
            tambahan, Penerima Dana sepakat untuk membayar biaya-biaya seperti
            semua bentuk denda, biaya keterlambatan pembayaran, biaya penagihan,
            dan biaya lain kepada POHON DANA sebagai hasil dari kegiatan layanan
            yang dilakukan oleh POHON DANA kepada Penerima Dana. Biaya dimaksud
            telah dihitung dan ditetapkan oleh POHON DANA dan sewaktu-waktu
            dapat berubah. Segala bentuk penetapan dan perubahan biaya akan
            diberitahukan kepada Penerima Dana
          </li>
          <li className="text-primary text-size-sub-body-2">
            <div>
              Bahwa biaya denda yang dimaksud pada point 4.2. di atas berupa :{" "}
              <br />
              <ul className="list-abjad">
                <li>
                  Biaya Denda Keterlambatan : 4,5% per bulan dengan detail
                  perhitungan : (4,5% x nilai cicilan yang terlambat dibayar x
                  lama hari/30)
                </li>
                <li>Biaya Penagihan per periode jatuh tempo : Rp. 50.000,-</li>
              </ul>
            </div>
          </li>
          <li className="text-primary text-size-sub-body-2">
            Untuk Penerima Dana yang merupakan karyawan dari perusahaan rekanan
            yang menjalin kerjasama dengan POHON DANA, pembayaran cicilan
            /angsuran Pendanaan dapat dilakukan dengan pemotongan gaji oleh
            pejabat HRD atau pihak yang ditunjuk oleh perusahaan rekanan
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    title: "Representasi dan Jaminan",
    content: (
      <React.Fragment>
        <div className="text-primary text-size-sub-body-2 pt-3">
          Penerima Dana menyatakan dan menjamin kepada POHON DANA bahwa Penerima
          Dana :
        </div>
        <ul className="sub-list">
          <li className="text-primary text-size-sub-body-2">
            Adalah Warga Negara Indonesia yang sah
          </li>
          <li className="text-primary text-size-sub-body-2">
            Perusahaan yang didirikan berdasarkan Hukum Indonesia
          </li>
          <li className="text-primary text-size-sub-body-2">
            Mampu membayar utang dan tidak ada alasan yang menyatakan bahwa
            Peminjam tidak mampu membayar utangnya ketika jatuh tempo dan harus
            dibayar
          </li>
          <li className="text-primary text-size-sub-body-2">
            Memiliki kapasitas hukum dan telah memperoleh perizinan dan/atau
            persetujuan yang diperlukan berdasarkan Hukum atau perjanjian lain
            yang mana Peminjam terikat untuk mengikatkan diri dalam Syarat dan
            Ketentuan dan melaksanakan kewajiban yang tertera dalam Syarat dan
            Ketentuan serta perjanjian lainnya terkait. Selanjutnya, Peminjam
            harus sudah mengambil langkah yang diperlukan untuk mengikatkan
            dirinya dalam Perjanjian dan melakukan kewajiban yang terdapat dalam
            Syarat dan Ketentuan serta perjanjian lainnya terkait
          </li>
          <li className="text-primary text-size-sub-body-2">
            Kewajiban yang diasumsikan mengikat kepada Peminjam dalam Syarat dan
            Ketentuan serta perjanjian lainnya terkait juga dianggap legal, sah,
            mengikat, dan dapat ditegakkan kepada Peminjam
          </li>
          <li className="text-primary text-size-sub-body-2">
            Semua informasi yang diberikan Peminjam kepada POHON DANA adalah
            benar dan akurat secara materi dan sesuai dengan tanggal dokumen
            diberikan atau tanggal yang tertera pada dokumen
          </li>
          <li className="text-primary text-size-sub-body-2">
            Tidak ada tindakan hukum, gugatan, atau proses hukum, atau didepan
            pengadilan, sidang, badan pemerintahan, agensi atau badan resmi atau
            arbitrasi (baik dalam proses atau akan diajukan) yang dapat
            berdampak pada legalitas, keabsahan, atau penegakan Syarat dan
            Ketentuan ini atau perjanjian lainnya yang terkait, atau
            mempengaruhi kemampuan Peminjam untuk menjalankan kewajiban
          </li>
          <li className="text-primary text-size-sub-body-2">
            Penerima dana tidak memiliki pendanaan pada lebih dari 3 (tiga)
            penyelenggara LPBBTI termasuk POHON DANA sebelum memberikan
            pendanaan kepada penerima dana
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    title: "Kuasa",
    content: (
      <React.Fragment>
        <div className="text-primary text-size-sub-body-2 pt-3">
          Peminjam memberikan kuasa POHON DANA untuk :
        </div>
        <ul className="sub-list">
          <li className="text-primary text-size-sub-body-2">
            Melaksanakan pengecekan kredit kepada Peminjam
          </li>
          <li className="text-primary text-size-sub-body-2">
            Mendapatkan dan melakukan verifikasi informasi mengenai Peminjam,
            sesuai dengan hak mutlak POHON DANA jika dianggap perlu
          </li>
          <li className="text-primary text-size-sub-body-2">
            Menggunakan semua sumber relevan, yang dapat digunakan oleh POHON
            DANA untuk menyediakan informasi yang dibutuhkan oleh POHON DANA
            terkait dengan fasilitas Pinjaman yang diberikan
          </li>
          <li className="text-primary text-size-sub-body-2">
            <div>
              Mengungkapkan informasi dan/atau data terkait Peminjam dan
              rekening-rekeningnya, dan/atau kartu kredit yang dimiliki (jika
              ada) kepada POHON DANA, atau informasi lainnya yang dipandang
              penting oleh POHON DANA di : <br />
              <ul className="list-abjad">
                <li>
                  Kantor perwakilan dan cabang dan/atau perusahaan atau
                  perusahaan asosiasi terkait Peminjam, pada juridiksi manapun
                </li>
                <li>Pemerintah atau badan pemerintahan atau badan otoritas</li>
                <li>
                  Setiap calon pengalihan hak Peminjam atau pihak yang telah
                  atau dapat memiliki hubungan kontraktual dengan Peminjam dalam
                  kaitannya dengan fasilitas Pinjaman
                </li>
                <li>Biro kredit, termasuk anggota biro kredit tersebut</li>
                <li>
                  Setiap pihak ketiga, penyedia jasa, agen, atau rekan bisnis
                  (termasuk, tidak terbatas pada, referensi kredit atau agen
                  evaluasi) dimanapun situasinya mungkin terjad
                </li>
              </ul>
            </div>
          </li>
          <li className="text-primary text-size-sub-body-2">
            Mendapatkan dan melakukan verifikasi informasi mengenai Peminjam
            kepada perusahaan rekanan yang menjalin kerjasama dengan POHON DANA,
            termasuk menjalankan point 4.4. di atas, dan Peminjam tidak dapat
            menuntut POHON DANA atas segala tindakan yang dijalankan sebagaimana
            mutlak telah menjadi kesepakatan antara perusahaan rekanan dengan
            POHON DANA
          </li>
          <li className="text-primary text-size-sub-body-2">
            Kepada pihak yang mebuka informasi yang diperbolehkan oleh Hukum
            untuk membuka informasi
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    title: "Ganti Rugi",
    content: (
      <React.Fragment>
        <div className="text-primary text-size-sub-body-2 pt-3">
          Peminjam setuju untuk mengganti rugi POHON DANA dan Pegawainya
          terhadap atas semua kerugian, pajak, biaya, biaya hukum, dan kewajiban
          (saat ini, di masa yang akan datang, kontigensi, atau apapun yang
          berbasis ganti rugi), yang diderita oleh POHON DANA sebagai hasil atau
          hubungan dari pelanggaran Syarat dan Ketentuan atau perjanjian lainnya
          terkait yang dilakukan oleh Peminjam dan/atau tindakan yang dilakukan
          oleh POHON DANA ketika terjadinya pelanggaran Syarat dan Ketentuan
          atau perjanjian lainnya yang terkait oleh Peminjam
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Batasan Tanggung Jawab Pohon Dana",
    content: (
      <React.Fragment>
        <div className="text-primary text-size-sub-body-2 pt-3">
          POHON DANA ataupun Pegawainya tidak bertanggung jawab atas dan ketika
          terjadi pelanggaran Perjanjian, (termasuk kelalaian atau pelanggaran
          kewajiban), secara kontraktual untuk :
        </div>
        <ul className="list-numbering">
          <li>terjadinya kehilangan keuntungan, bisnis atau pendapatan</li>
          <li>
            setiap biaya atau beban, atau secara tidak langsung atau langsung,
            diderita atau disebabkan oleh Peminjam sebagai hasil atau dalam
            hubungan dengan ketentuan penyediaan Jasa.
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    title: "Waktu",
    content: (
      <React.Fragment>
        <div className="text-primary text-size-sub-body-2 pt-3">
          Adalah masa atau periode dalam Perjanjian bagi peminjam untuk
          melaksanakan dan memenuhi kewajibannya secara tepat waktu.
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Pengesampingan",
    content: (
      <React.Fragment>
        <div className="text-primary text-size-sub-body-2 pt-3">
          Kegagalan atau penundaan dalam pelaksanaan suatu hak, kewenangan, atau
          keistimewaan terkait Syarat dan Ketentuan ini tidak akan dianggap
          sebagai pengabaian hak dan pelaksanaan satu atau sebagian dari suatu
          hak, kewenangan atau keistimewaan tidak akan dianggap menghalangi
          segala konsekuensinya atau kelanjutan pelaksaan dari suatu hak,
          kewajiban atau keistimewaan
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Amandemen",
    content: (
      <React.Fragment>
        <div className="text-primary text-size-sub-body-2 pt-3">
          POHON DANA dapat sewaktu-waktu, memberikan pemberitahuan kepada
          Peminjam atas terjadinya amandemen, perubahan, revisi, tambahan, atau
          perubahan lainnya untuk Syarat dan Ketentuan melalui surat, surat
          elektronik, atau cara lain yang dianggap sesuai oleh POHON DANA.
          Perubahan berlaku sejak dan dimulai dari tanggal yang ditentukan dalam
          pemberitahuan atau jika tanggal tersebut tidak ditulis, adalah sejak
          dan dimulai dari tanggal terjadinya pemberitahuan. Tanpa mengurangi
          atas ketentuan sebelumnya, dimulainya atau berlanjutnya Jasa setelah
          terjadi perubahan tersebut akan dianggap sebagai persetujuan dari
          Peminjam akan berlanjutnya perubahan tersebut
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Hukum yang Berlaku dan Yuridiksi",
    content: (
      <React.Fragment>
        <ul className="sub-list">
          <li className="text-primary text-size-sub-body-2">
            Syarat dan Ketentuan ini akan diatur dan ditafsirkan sesuai dengan
            ketentuan Hukum Republik Indonesia
          </li>
          <li className="text-primary text-size-sub-body-2">
            Dalam rangka mematuhi gugatan atau tindakan hukum yang terkait
            perselisihan yang timbul akibat atau dalam kaitannya dengan Syarat
            dan Ketentuan ini, setiap pihak akan tunduk pada juridiksi Badan
            Arbitrase Nasional Indonesia (BANI).
          </li>
        </ul>
      </React.Fragment>
    ),
  },
];
function TncContent() {
  const sectionRef = useRef<(HTMLDivElement | null)[]>([]);

  const handleClick = (index: number) => {
    if (sectionRef.current[index]) {
      const element = sectionRef.current[index];
      const offset = 60;
      if (element) {
        const top =
          element.getBoundingClientRect().top + window.scrollY - offset;
        window.scrollTo({ top, behavior: "smooth" });
      }
    }
  };

  return (
    <React.Fragment>
      <Container className="border-bottom pb-5">
        <div className="text-size-body-head-2 text-primary border-bottom">
          Daftar Isi
        </div>
        <Row>
          <Col>
            <ul className="list-content ps-4">
              {CONTENT.map((a, i) => {
                return (
                  <li
                    key={a.title}
                    onClick={() => handleClick(i)}
                    className="fw-bold cursor-pointer text-size-sub-body-2 pt-2"
                  >
                    {a.title}
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <ul className="main-list">
          {CONTENT.map((a, i) => {
            return (
              <li
                key={`content-${a.title}`}
                className="mt-4 pb-4 border-bottom"
              >
                <div
                  ref={(el) => {
                    sectionRef.current[i] = el;
                  }}
                >
                  <div className="text-primary fw-extra-bold text-size-sub-headline-2 text-uppercase">
                    {a.title}
                  </div>
                  {a.content}
                </div>
              </li>
            );
          })}
        </ul>
      </Container>
    </React.Fragment>
  );
}

export default TncContent;

import React from "react";
import {  Container } from "react-bootstrap";
import ComplaintStep, {
  ComplaintStepContent,
} from "./components/complaint-step";

const CONTENTS: ComplaintStepContent[] = [
  {
    title: "Langkah 1",
    stepNumber: "1",
    description:
      "Pengguna menghubungi Pohon Dana melalui saluran pengaduan yang ditentukan",
  },
  {
    title: "Langkah 2",
    stepNumber: "2",
    description: `Staff Pohon Dana mencatat laporan dan memproses ke bagian terkait`,
  },
  {
    title: "Langkah 3",
    stepNumber: "3",
    description: `Proses pengaduan maksimal 5 hari kerja. Anda sudah dihubungi melalui saluran resmi Pohon Dana`,
  },
];

function ComplaintContent() {
  return (
    <React.Fragment>
      <div className="text-size-title-2 text-center fw-extra-bold text-secondary-dark mb-4 mb-lg-5">
        Mekanisme dan Layanan Pengaduan Pengguna
      </div>

      <Container className="my-5">
        <ComplaintStep contents={CONTENTS} />
      </Container>
      <Container className="my-5">
        <div className="content-card">
          <div
            style={{ textAlign: "justify" }}
            className=" text-primary text-size-sub-body"
          >
            Mekanisme Layanan pengaduan pengguna yang dilakukan oleh PT Pohon
            Dana Indonesia (selanjutnya disebut sebagai Pohon Dana) senantiasa
            mengacu pada Peraturan Otoritas Jasa Keuangan Nomor 18/POJK.07/2018
            tentang Layanan Pengaduan Konsumen di Sektor Jasa Keuangan dan Surat
            Edaran Otoritas Jasa Keuangan Nomor 17/POJK.07/2018 tentang Pedoman
            Pelaksanaan Layanan Pengaduan Konsumen Di Sektor Jasa Keuangan.
            Pohon Dana berkomitmen untuk memberikan bantuan yang maksimal dalam
            rangka mengelola hubungan serta menyajikan informasi secara jelas
            dan terbuka kepada Anda. Pengaduan Anda akan ditampung,
            dikoordinasikan, dan diselesaikan oleh Unit Customer Service (CS)
            Pohon Dana.
          </div>

          <div className="py-3">
            <div className=" text-primary fw-extra-bold text-size-sub-body">
              Saluran pengaduan pengguna
            </div>

            <div className=" text-primary  text-size-sub-body-2 pt-3">
              <ul className="listAlpha">
                <li>
                  <span className="fw-extra-bold">Email </span>
                  <br />
                  <span>
                    Bagi Pengguna yang akan melakukan bentuk pengaduan/ keluhan
                    yang diberikan secara tertulis dapat mengirimkan
                    aduan/keluhan melalui email yang telah disediakan oleh
                    Pohon Dana melalui email : customerservice@pohondana.id
                    sesuai dengan jam operasional Pohon Dana dari Senin - Jumat
                    Pukul 08:30 - 17:00 WIB.
                  </span>
                </li>
                <li>
                  <span className="fw-extra-bold">Telepon </span>
                  <br />
                  <span>
                    Bagi Pengguna yang akan melakukan bentuk pengaduan/ keluhan
                    yang diberikan secara lisan atau verbal dapat menghubungi
                    customer service melalui nomor telepon (021)5229660 sesuai
                    dengan jam operasional Pohon Dana dari Senin - Jumat Pukul
                    08:30 - 17:00 WIB.
                  </span>
                </li>
                <li>
                  <span className="fw-extra-bold">Whatsapp </span>
                  <br />
                  <span>
                    Bagi Pengguna yang akan melakukan bentuk pengaduan/ keluhan
                    yang diberikan secara tertulis dan singkat dapat melalui
                    WhatsApp sesuai dengan jam operasional Pohon Dana dari Senin
                    - Jumat Pukul 08:30 - 17:00 WIB dengan nomor WhatsApp
                    sebagai berikut :
                    <ul>
                      <li>Peminjam (Borrower) : 08111829660</li>
                      <li>Pendana (Lender) : 081901129660</li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-3">
            <div className=" text-primary fw-extra-bold text-size-sub-body">
              Alur Pengaduan
            </div>

            <div className=" text-primary  text-size-sub-body-2 pt-3">
              <ul className="listAlpha">
                <li>
                  <span>
                    Pengguna dapat menyampaikan pengaduan melalui salah satu
                    saluran pengaduan yang terdapat pada poin 2 diatas, bentuk
                    pengaduan yang diterima berupa :
                    <ul>
                      <li>Pengaduan yang bersifat Nominal</li>
                      <li>Pengaduan yang bersifat Keluhan Pelayanan</li>
                    </ul>
                  </span>
                </li>
                <li>
                  <span>
                    Pengaduan yang diterima akan diidentifikasi dan dianalisa
                    oleh Tim Cuctomer Service maupun Tim Khusus yang berkaitan.
                  </span>
                </li>
                <li>
                  <span>
                    Dalam melakukan pengajuan keluhan/pengaduan Pengguna
                    berkewajiban memberikan informasi pengguna termasuk namun
                    tidak terbatas pada :
                    <ul>
                      <li>Nama Lengkap Pengguna</li>
                      <li>Nomor Telepon Pengguna</li>
                      <li>Jenis Pengaduan yang diajukan</li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-3">
            <div className=" text-primary fw-extra-bold text-size-sub-body">
              Penyelesaian Pengaduan
            </div>

            <div className=" text-primary  text-size-sub-body-2 pt-3">
              <ul className="listAlpha">
                <li>
                  <span>
                    Penyelesaian pengaduan yang dilakukan didasarkan pada jenis
                    pengaduan yang Kami terima, pengaduan yang bersifat tertulis
                    akan diselesaikan dalam waktu maksimal{" "}
                    <span className="fw-bold">10 (sepuluh) hari kerja </span>{" "}
                    sedangkan pengaduan yang bersifat lisan/verbal akan
                    diselesaikan dalam waktu maksimal{" "}
                    <span className="fw-bold">5 (lima) hari kerja </span> namun
                    dengan tetap memperhatikan ketentuan yang ada pada unit
                    kerja tertentu.
                  </span>
                </li>
                <li>
                  <span>
                    Jenis pengaduan yang kami terima berupa permasalahan
                    mengenai status pengembalian dana, informasi status
                    pencairan dana, kendala pada sistem dan atau hal-hal yang
                    berkaitan dengan permasalahan layanan yang disediakan oleh
                    Pohon Dana.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-3">
            <div className=" text-primary fw-extra-bold text-size-sub-body">
              Penolakan Pengaduan
            </div>

            <div className=" text-primary  text-size-sub-body-2 pt-3">
              <span>
                Pohon Dana berhak untuk melakukan penolakan yang diajukan oleh
                pengguna, dengan ketentuan penolakan sebegai berikut:
              </span>
              <ul className="listAlpha">
                <li>
                  <span>
                    Pohon Dana berhak menolak pengaduan apabila pengguna tidak
                    memberikan informasi sebagaimana yang telah disebutkan pada{" "}
                    <span>poin 2 huruf c</span>.
                  </span>
                </li>
                <li>
                  <span>
                    Pohon Dana berhak menolak pengaduan apabila pengguna tidak
                    memberikan dokumen pendukung yang diminta oleh Pohon Dana
                    (disesuaikan dengan pengaduan yang diterima).
                  </span>
                </li>
                <li>
                  <span>
                    Pengaduan yang diterima tidak berkaitan dengan layanan yang
                    diberikan oleh Pohon Dana.
                  </span>
                </li>
                <li>
                  <span>
                    Pengaduan yang diterima tidak diatur dan/atau tidak
                    disepakati dalam perjanjian atau dokumen lain yang
                    berkaiatan dengan layanan Pohon Dana
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-3">
            <div className=" text-primary fw-extra-bold text-size-sub-body">
              Pelaporan Pengaduan kepada Otoritas Jasa Keuangan
            </div>

            <div className="text-primary text-size-sub-body-2 pt-3">
              Pohon Dana akan selalu mematuhi ketentuan yang berlaku, khususnya
              dalam melakukan penyampaian pelaporan layanan pengaduan konsumen
              yang dilakukan secara berkala sesuai dengan bentuk yang telah
              ditentukan oleh Otoritas Jasa Keuangan.
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default ComplaintContent;

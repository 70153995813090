export function calculateInstallment(
  amount: number,
  monthlyInterest: number,
  tenour: number
) {
  monthlyInterest = monthlyInterest / 100;
  const installment = Math.round(
    (amount * monthlyInterest) / (1 - Math.pow(1 + monthlyInterest, -tenour))
  );

  const totalReturn = installment * tenour;
  return {
    installment,
    totalReturn,
  };
}

import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Image, Row } from 'react-bootstrap';
import { PRODUCTS } from '../../constants/products';
import { Form } from 'react-bootstrap';
import businessImage from '../../assets/images/products/business.png'
import individualImage from '../../assets/images/products/individual.png'
import { ENV } from '../../config/env';

interface IProps {
  onCancel: () => void
  onFinish: () => void
  onFailed: () => void
}

const BUSINESS_TYPE = {
  business: {
    key: "business",
    title: "Bisnis",
    image: businessImage
  },
  individual: {
    key: "individual",
    title: "Perorangan",
    image: individualImage
  },
}

const QUESTIONS = [
  "Apakah perusahaan sudah beroperasional paling tidak selama 5 tahun?",
  "Apakah perusahaan profit selama 3 tahun terakhir ?",
  "Apakah ada Aset selain Aset tetap?"
]

function WorkingCapitalRegistrationForm({ onCancel, onFinish, onFailed }: IProps) {
  const [businessType, setBusinessType] = useState<string | null>(null)
  const [answer, setAnswer] = useState(QUESTIONS.map(_ => ""))
  const [showError, setShowError] = useState(false)
  const [showErrorStop, setShowErrorStop] = useState(false)

  useEffect(() => {
    if (showErrorStop || businessType === "individual") {
      onFailed()
    }
  }, [showErrorStop, businessType, onFailed])
  
  const handleChangeAnswer = (val: string, idx: number) => {
    setAnswer((prevState) => {
      prevState[idx] = val
      return [...prevState]
    });
    setShowError(false);
  }

  const handleContinueRegistration = () => {
    let checkAnswer = answer.filter(a => a === "").length > 0
    if (checkAnswer) {
      setShowError(true)
      return
    }
    checkAnswer = answer.filter(a => a === "Tidak").length > 0
    if (checkAnswer) {
      setShowErrorStop(true)
      return
    }
    window.open(PRODUCTS.capitalLoan.link, "_blank")
    onFinish()
  }

  if (businessType === null) {
    return (
      <React.Fragment>
        <div className="mb-4 text-size-sub-body-2 text-center">
          Anda akan mendaftar sebagai  Perorangan atau Bisnis?
        </div>
        <Row>
          {Object.values(BUSINESS_TYPE).map((a, aIdx) => {
            return (
              <Col md={6} sm={12} key={a.key} className={aIdx > 0 ? "mt-2 mt-md-0" : ""}>
                <Card className="h-100 card-hover cursor-pointer" onClick={() => setBusinessType(a.key) }>
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="me-2 text-size-body-head-2 text-primary-main ps-4">
                        {a.title}
                      </div>
                      <div>
                        <Image src={a.image} />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
        </Row>
        <div className="mt-5 text-center">
          <Button variant="link" onClick={onCancel} className="text-size-sub-body-2">
            Lihat produk pinjaman lainnya
          </Button>
        </div>
      </React.Fragment>
    )
  }
  // if (businessType === "individual") return null
  if (businessType === "individual") {
    window.open(`${ENV.pohon_dana_url}/register/borrower/individu`);
    onFinish();
  }
  return (
    <React.Fragment>
      {QUESTIONS.map((q, qIdx) => {
        return (
          <div key={`question-${qIdx}`} className="mb-4">
            <div className="mb-2 text-size-sub-body-2">{q}</div>
            <div className="d-flex gap-5">
              <Form.Check
                className="rounded-circle"
                type="checkbox"
                label={`Ya`}
                checked={answer[qIdx] === "Ya"}
                onChange={(e) => handleChangeAnswer("Ya", qIdx)}
              />
              <Form.Check
                className="rounded-circle"
                type="checkbox"
                label={`Tidak`}
                checked={answer[qIdx] === "Tidak"}
                onChange={(e) => handleChangeAnswer("Tidak", qIdx)}
              />
            </div>
          </div>
        )
      })}
      {showError && (
        <Alert variant="danger" className="text-size-label py-2">Mohon isi semua pertanyaan di atas</Alert>
      )}
      <div className="mt-5 d-flex flex-column flex-md-row align-items-center justify-content-md-between">
        <div className="order-1 order-md-2">
          <Button variant="primary" onClick={handleContinueRegistration}>
            Lanjut Registrasi
          </Button>
        </div>
        <div className="order-2 order-md-1">
          <Button variant="link" onClick={onCancel}>
            Lihat produk pinjaman lainnya
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WorkingCapitalRegistrationForm;

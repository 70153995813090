import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  ButtonGroup,
  Button,
  Form,
} from "react-bootstrap";
import heroBackground from "../../assets/images/hero-background-about.png";
import partnerHeader from "../../assets/images/partner/partner-header.png";
import PartnerList from "./partner-list";
import { useLocation } from "react-router";
import { FaSearch } from "react-icons/fa";

function Partner() {
  const [activeFilters, setActiveFilters] = useState<string[]>([
    "employee-loan",
    "invoice-financing",
    "po-financing",
    "working-capital-loan",
  ]);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasResults, setHasResults] = useState(true);
  const location = useLocation();
  const [hotelCount, setHotelCount] = useState(1);
  const [foodCount, setFoodCount] = useState(1);
  const [healthCount, setHealthCount] = useState(1);
  const [othersCount, setOthersCount] = useState(1);
  const [isNoImage, setIsNoImage] = useState(false);

  const productFilters = [
    { id: "employee-loan", name: "Employee Loan", color: "#009344" },
    { id: "invoice-financing", name: "Invoice Financing", color: "#05A0C0" },
    { id: "po-financing", name: "PO Financing", color: "#4A4A49" },
    {
      id: "working-capital-loan",
      name: "Working Capital Loan",
      color: "#7A288A",
    },
  ];

  const toggleFilter = (id: string) => {
    let updatedFilters = activeFilters.includes(id)
      ? activeFilters.filter((filter) => filter !== id)
      : [...activeFilters, id];

    if (updatedFilters.length === 0) {
      updatedFilters = productFilters.map((f) => f.id);
    }

    setActiveFilters(updatedFilters);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get("filter");

    if (filter) {
      setActiveFilters([filter]);
    } else {
      setActiveFilters([
        "employee-loan",
        "invoice-financing",
        "po-financing",
        "working-capital-loan",
      ]);
    }
  }, [location.search]);

  useEffect(()=>{
    let total = hotelCount + healthCount +  foodCount + othersCount;
    if(total < 1){
      setIsNoImage(true);
    }else{
      setIsNoImage(false);
    }
  },[hotelCount,healthCount,foodCount,othersCount])

  const handleSearchResults = (hasData: boolean) => {
    setHasResults((prev) => prev || hasData);
  };

  return (
    <div className="main-container" id="partner-page">
      <div
        className="hero-background-container about-banner position-relative"
        style={{
          backgroundImage: `url(${heroBackground})`,
        }}
      >
        <Container>
          <Row className="text-white justify-content-between">
            <Col
              xl={7}
              lg={6}
              md={12}
              sm={12}
              className="h-100 mt-xl-5 pt-lg-5"
            >
              <h1 className="text-size-title-2 fw-extra-bold banner-product-title">
                Peminjam Pohon Dana
              </h1>
              <div className="mt-2 text-size-body-head-2">
                Brand-brand yang telah percaya dan menggunakan layanan Pohon
                Dana
              </div>
            </Col>
            <Col
              xl={5}
              lg={6}
              className="d-none d-lg-flex justify-content-end text-end pt-lg-5"
            >
              <Image src={partnerHeader} fluid />
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mb-5 py-5">
        <div className="d-flex mb-4">
          <div className="form-search">
            <div className="d-flex justify-content-center align-items-center p-2">
              <FaSearch />
            </div>
            <div style={{width:"100%"}} className="d-flex justify-content-center align-items-center">
              <Form.Control
                className="form-input-search"
                 type="text"
                placeholder="Cari Brand Tertentu"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                 
                }}
              />
            </div>
          </div>
          <div className="p-2"></div>
         
        </div>

        {/* Filter Buttons */}
        <ButtonGroup className="filter-group" style={{ width: "60%" }}>
          {productFilters.map((filter) => (
            <Button
              key={filter.id}
              onClick={() => toggleFilter(filter.id)}
              className={`filter-button ${activeFilters.includes(filter.id) ? "active" : ""}`}
              style={{
                borderColor: filter.color,
                color: filter.color,
                backgroundColor: activeFilters.includes(filter.id)
                  ? filter.color
                  : "#C9C9C9",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  backgroundColor: filter.color,
                  marginRight: "8px",
                }}
              ></span>
              {filter.name}
            </Button>
          ))}
        </ButtonGroup>

        {(!hasResults || isNoImage )&& (
          <div className="d-flex justify-content-center align-items-center text-center py-5">
            <h5 className="text-muted">
              Perusahaan yang Anda cari tidak ditemukan
            </h5>
          </div>
        )}
        {/* Partner Lists */}
        <PartnerList
          title="Hotel"
          folder="hotel"
          filter={activeFilters}
          searchQuery={searchQuery}
          onSearchResult={handleSearchResults}
          onImageCount={(count)=>{
            setHotelCount(count);
          }}
        />
        <PartnerList
          title="Food and Beverages"
          folder="food_beverages"
          filter={activeFilters}
          searchQuery={searchQuery}
          onSearchResult={handleSearchResults}
          onImageCount={(count)=>{
            setFoodCount(count);
          }}
        />
        <PartnerList
          title="Healthcare"
          folder="healthcare"
          filter={activeFilters}
          searchQuery={searchQuery}
          onSearchResult={handleSearchResults}
          onImageCount={(count)=>{
            setHealthCount(count)
          }}
        />
        <PartnerList
          title="Others"
          folder="others"
          filter={activeFilters}
          searchQuery={searchQuery}
          onSearchResult={handleSearchResults}
          onImageCount={(count)=>{
            setOthersCount(count);
          }}
        />
      </Container>
    </div>
  );
}

export default Partner;

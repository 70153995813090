import React,{useEffect} from "react";
import { Col, Row, Image } from "react-bootstrap";
import imageLists from "../../assets/images/partner/list.json";

interface IProps {
  title: string;
  folder: string;
  filter: string[];
  searchQuery: string;
  onSearchResult: (hasData: boolean) => void;
  onImageCount: (count: number) => void;
}

function PartnerList({ title, folder, filter, searchQuery, onSearchResult, onImageCount  }: IProps) {
  const images =
    Object.entries(imageLists)
      .filter(
        ([folderName, content]) => content !== null && folderName === folder
      )
      .map(([_, content]) => {
        return Object.values(content)
          .map((entry: any) => {
            const productsArray = Array.isArray(entry.products)
              ? entry.products
              : [entry.products];

            const productColors = productsArray.map((product: string) => {
              if (product === "po-financing") return "#4A4A49";
              if (product === "employee-loan") return "#009344";
              if (product === "invoice-financing") return "#05A0C0";
              return "#7A288A";
            });

            return {
              src: require(
                `../../assets/images/partner/${folder}/${entry.file}`
              ),
              products: productsArray,
              colors: productColors,
              imageName: entry.image_name.toLowerCase(),
            };
          })
          .filter(
            (image) =>
              (image.products.some((product: string) =>
                filter.includes(product)
              ) ||
                filter.length === 0) &&
              (searchQuery === "" ||
                image.imageName.includes(searchQuery.toLowerCase()))
          );
      })[0] || [];

  useEffect(() => {
    onSearchResult(images.length > 0);
  }, [images.length, onSearchResult]);

  useEffect(() => {
    onImageCount(images.length);
  }, [images.length, onImageCount]);
  return (
      <React.Fragment>
        {images.length > 0 && (
          <div className="my-4 py-4">
            <div className="fw-extra-bold text-size-sub-headline">{title}</div>
            <Row className="align-items-center text-center">
              {images.map((a, idx) => (
                <Col
                  xl={2}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  className="mt-4 mb-2 d-flex justify-content-center "
                  key={`partner-${idx}`}
                >
                  <div className="partner-card">
                    <div className="categoryProducts">
                      {a.colors.map((color: string, index: number) => (
                        <span
                          key={`circle-${idx}-${index}`}
                          style={{
                            right: 5 + index * 15,
                            backgroundColor: color,
                          }}
                        ></span>
                      ))}
                    </div>

                    <Image src={a.src} fluid className="mt-2" />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ) }
      </React.Fragment>
    
  );
}

export default PartnerList;

import { configureStore } from '@reduxjs/toolkit'
import dataSlice from './dataslice';

const store = configureStore({
  reducer: {
    data: dataSlice,
  },
})
export default store

export type AppDispatch = typeof store.dispatch;  // This ensures dispatch is typed correctly
export type RootState = ReturnType<typeof store.getState>;

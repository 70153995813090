import React from "react";
import { Container, Accordion, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { NAVIGATIONS } from "../..";

function FaqContent() {
    const navigate = useNavigate();
  return (
    <React.Fragment>
      <Container className="mb-5">
        <Accordion className="custom-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Apakah Pohon Dana telah berizin dan diawasi oleh OJK?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Pohon Dana merupakan Penyelenggara Layanan Pendanaan Bersama
                Berbasis Teknologi Informasi ( LPBBTI) yang telah mendapatkan
                izin dari Otoritas Jasa Keuangan (OJK) sebagaimana tertuang
                dalam Surat Keputusan Anggota Dewan Komisioner Otoritas Jasa
                Keuangan Nomor : KEP-126/D.05/2019 tanggal 13 Desember 2019
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Bagaimana cara dan syarat pengajuan pinjaman ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                <ol>
                  <li>
                    <span className="fw-bold">Persyaratan Peminjam </span>
                    <br />
                    <ul>
                      <li>Warga Negara Indonesia</li>
                      <li>
                        Berusia 18 Tahun hingga batas usia yang ditentukan
                      </li>
                      <li>Menetap di Indonesia</li>
                      <li>Memiliki Kartu Tanda Penduduk</li>
                      <li>Memiliki Rekening Bank an Peminjam</li>
                    </ul>
                  </li>
                  <li>
                    <span className="fw-bold">Persyaratan Peminjam </span>
                    <br />
                    <ul>
                      <li>Warga Negara Indonesia</li>
                      <li>
                        Berusia 18 Tahun hingga batas usia yang ditentukan
                      </li>
                      <li>Menetap di Indonesia</li>
                      <li>Memiliki Kartu Tanda Penduduk</li>
                      <li>Memiliki Rekening Bank an Peminjam</li>
                    </ul>
                  </li>
                </ol>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Berapa lama tenor pinjaman yang diberikan ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Maksimal 12 bulan
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Berapa lama pinjaman cair ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                1 x 24 jam semenjak konfirmasi pinjaman disetujui
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Berapa suku bunga yang diberikan ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Biaya Bunga pinjaman yang diberikan antara{" "}
                <span className="fw-bold">1.8% sd 3,5% </span>perbulan
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Mengapa pengajuan pinjaman tidak disetujui/ditolak ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Tidak direkomendasikan dan tidak masuk dalam skor kredit
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Risiko yang saya terima apabila terlambat bayar pinjaman ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Dikenakan denda keterlambatan sesuai dengan kesepakatan
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Apakah saya dapat menerima Salinan perjanjian pinjaman yang
                telah disetujui ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Peminjam dapat mengunduh salinan perjanjian setelah pengajuan
                disetujui
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Apakah data pribadi yang diberikan kepada Pohon Dana tersimpan
                dengan aman ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Data pribadi yang diberikan tersimpan dengan aman pada pusat
                data fintech
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Bagaimana apabila terdapat keluhan/pengaduan terkait pinjaman ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Bisa mengehubungi layanan pengaduan yang tertera di halaman
                website PohonDana yaitu www.pohondana.id
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <div className="text-secondary-dark text-size-body-head-2 fw-bold">
                Bagaimana melakukan pengembalian pinjaman yang saya terima ?
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text-secondary text-size-sub-body-2 ">
                Pengembalian pinjaman dilalukan melalui transfer bank ke Virtual
                Account
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <Container className="justify-content-center mb-5 ">
        <div className="text-center  text-secondary text-size-sub-body-2">
          Masih ada pertanyaan lebih seputar Pohon Dana?
        </div>
        <div className="text-center pt-3">
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              navigate(NAVIGATIONS['contact']);
            }}
          >
            Kontak Kami
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default FaqContent;

import React from "react";
import heroBackground from "../../../assets/images/hero-background.png";
import { Col, Container, Image, Row } from "react-bootstrap";
import { ProductInfo } from "../../../constants/products";
import elefinLogo from "../../../assets/images/elefin-black.png";
import { ELEFIN_HOME } from "../../../constants";

interface IProps {
  product: ProductInfo;
}

function ProductBanner({ product }: IProps) {
  return (
    <React.Fragment>
      <div
        className="hero-background-container product-banner position-relative"
        style={{
          backgroundImage: `url(${heroBackground})`,
        }}
      >
        <Container>
          <div>
            <Row className="text-white justify-content-between">
              <Col
                xl={7}
                lg={6}
                md={12}
                sm={12}
                className="h-100 mt-xl-5 pt-lg-5"
              >
                <div>
                  <div>
                    <h1 className="text-size-title-2 fw-extra-bold banner-product-title">
                      {product.title}
                    </h1>
                    <div className="mt-2 text-size-body-head-2">
                      {product.bannerDescription}
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xl={5}
                lg={6}
                className="d-none d-lg-flex justify-content-end text-end pt-lg-5"
                style={{ overflow: "hidden" }}
              >
                <Image src={product.bannerImage} fluid />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col xl={10} lg={11} md={12}>
            <div className="my-5 text-center">
              <div className="fw-extra-bold text-size-title-2 mb-4 text-secondary-dark">
                {product.title}
              </div>
              <div className="text-size-body-head-2 text-primary">
                {product.description}
              </div>
              {product.key === "poFinancing" && (
                <React.Fragment>
                  <div className="text-size-body-head-2 mt-4 text-primary mb-4">
                    Premium Partner:
                  </div>
                  <div>
                    <a href={ELEFIN_HOME} target="_blank" rel="noreferrer">
                      <Image src={elefinLogo} fluid />
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ProductBanner;

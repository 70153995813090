import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import WorkingCapitalError from "./working-capital-error";
import WorkingCapitalRegistrationForm from "./working-capital-registration-form";

interface IProps {
  onHide: () => void;
}

function WorkingCapitalRegistrationModal({ onHide }: IProps) {
  const [failedCapitalLoan, setFailedCapitalLoan] = useState(false);

  return (
    <React.Fragment>
      <Modal show onHide={onHide} size={failedCapitalLoan ? "xl" : "lg"}>
        <Modal.Header closeButton>
          {failedCapitalLoan ? (
            <Modal.Title className="text-center fw-bold text-danger">
              Registrasi anda tidak dapat dilanjutkan!
            </Modal.Title>
          ) : (
            <Modal.Title className="text-center fw-bold">
              Registrasi Working Capital Loan
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col lg={10} md={12}>
              {failedCapitalLoan ? (
                <WorkingCapitalError onClose={onHide} />
              ) : (
                <WorkingCapitalRegistrationForm
                  onCancel={onHide}
                  onFinish={onHide}
                  onFailed={() => setFailedCapitalLoan(true)}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default WorkingCapitalRegistrationModal;

import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import { ProductInfo } from "../../../constants/products";

export interface ProductHowContent {
  title: string;
  description: ReactElement;
}

interface IProps {
  contents: ProductHowContent[];
  product: ProductInfo;
}

function ProductHow({ product, contents }: IProps) {
  return (
    <React.Fragment>
      <div className="mb-4 text-secondary text-size-body-head">Pengajuan</div>
      <Row className="align-items-start">
        <Col lg={6} md={12} sm={12}>
          <div className="text-size-title-2 fw-extra-bold text-secondary-dark text-center text-lg-start">
            <span className="text-primary-main">Proses Peminjaman</span> melalui{" "}
            <span className="text-primary-main">{product.title}</span>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} className="mt-4 mt-lg-0 ps-2">
          {contents.map((c, cIdx) => {
            const num = cIdx + 1;
            return (
              <Row key={`row-how-content-${cIdx}`} className="mb-4">
                <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto">
                  <div className="product-how-number text-size-sub-body-2 text-primary-main fw-extra-bold">
                    {num}
                  </div>
                </Col>
                <Col xl lg md sm xs>
                  <div className="mb-2 mb-lg-4 fw-extra-bold text-size-body-head">
                    {c.title}
                  </div>
                  <div className="text-size-sub-body-2">{c.description}</div>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ProductHow;

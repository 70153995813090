import React from "react";
import ProductBanner from "./components/product-banner";
import { EMPLOYEE_LOAN_INFO, PRODUCTS } from "../../constants/products";
import ProductBenefit, {
  ProductBenefitContent,
} from "./components/product-benefit";
import lowInterestIcon from "../../assets/images/products/low-interest.png";
import instantPayIcon from "../../assets/images/products/instant-pay.png";
import transparantIcon from "../../assets/images/products/transparancy.png";
import { Container } from "react-bootstrap";
import ProductClosingStatement from "./components/product-closing-statement";
// import { Link } from "react-router";
// import { NAVIGATIONS } from "../..";
import ProductInterest from "./components/product-interest";
import ProductEmployeeSimulation from "./components/product-employee-simulation";
import ProductPartner from "./components/product-partner";
// import ProductCard from "../../components/products/product-card";

const CONTENTS: ProductBenefitContent[] = [
  {
    title: "Terima Biaya Instan",
    image: instantPayIcon,
    description:
      "Dana diterima dalam waktu 2 jam setelah konfirmasi pihak HRD.",
  },
  {
    title: "Biaya Transparan",
    image: transparantIcon,
    description: `Biaya dibayarkan di muka sebesar Rp ${Intl.NumberFormat("id-ID").format(EMPLOYEE_LOAN_INFO.adminFee)} per transaksi. Tidak ada biaya tersembunyi lainnya.`,
  },
  {
    title: "Bunga Rendah",
    image: lowInterestIcon,
    description: `Bunga dimulai dari ${Intl.NumberFormat("id-ID").format(EMPLOYEE_LOAN_INFO.interestRate.start)}% per bulan disesuaikan dengan penghasilan karyawan dan tenor yang diambil.`,
  },
];

const partner = {
  title1 :"Perusahaan yang menggunakan",
  title2 :"Employee Loan",
  titleDescription:"",
  product: "employee-loan",
  description: "",
}

function EmployeeLoan() {
  const product = PRODUCTS.employeeLoan;

  return (
    <div className="main-container" id="product-page">
      <ProductBanner product={product} />
      <Container className="my-5">
        <ProductBenefit
          title={
            <React.Fragment>
              <span className="text-primary-main">Keuntungan</span>{" "}
              {product.title}
            </React.Fragment>
          }
          contents={CONTENTS}
        />
      </Container>
      <ProductInterest
        position="left"
        title={
          <React.Fragment>
            <span className="text-primary-main">Biaya</span> Pinjaman untuk{" "}
            <span className="text-primary-main">Karyawan</span>
          </React.Fragment>
        }
        interestMin={EMPLOYEE_LOAN_INFO.interestRate.start}
        interestMax={EMPLOYEE_LOAN_INFO.interestRate.end}
        caption={
          <React.Fragment>
            <div>
              Provisi dan biaya keterlambatan -{" "}
              <span className="fw-bold">Rp 0</span>
            </div>
            <div>
              Biaya admin - Rp{" "}
              {Intl.NumberFormat("id-ID").format(EMPLOYEE_LOAN_INFO.adminFee)} /
              transaksi
            </div>
          </React.Fragment>
        }
      />
      <ProductEmployeeSimulation />
      <ProductPartner data={partner} />

      <Container className="my-5">
        <ProductClosingStatement
          caption="employee-loan"
          title=""
          product={product}
          buttonText="Ajukan Pinjaman"
          additionalInfo={
            <React.Fragment>
              
            </React.Fragment>
          }
        />
      </Container>
    </div>
  );
}

export default EmployeeLoan;

export const DEFAULT_WEB_TITLE = "Pohon Dana";
export const ADDRESS_TOWER = "MAYAPADA TOWER 1, Lt. 8";
export const ADDRESS_STREET = [
  "Jl. Jendral Sudirman No.Kav. 28, Kuningan,",
  "Kota Jakarta Selatan, DKI Jakarta 12920, Indonesia",
];

export const CONTACTS = {
  whatsapp: "0818-189-660",
  whatsappAlt: "0819-01139-660",
  operationalTime: "Senin - Jumat 08.30 - 17.30 WIB",
  generalSupport: "021-5229660",
  email: "customerservice@pohondana.id",
};

export const ELEFIN_HOME = "https://elefin.id";
export const ELEFIN_SUPPLIER_REGISTRATION =
  "https://elefin.id/supplier/register";

import React, { JSX, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router";
import ScrollToTop from "./scroll-to-top";
import { fetchData } from "./reducers/dataslice";
import Home from "./pages/home";
import Header from "./components/header";
import Footer from "./components/footer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./reducers";
import EmployeeLoan from "./pages/products/employee-loan";
import InvoiceFinancing from "./pages/products/invoice-financing";
import PoFinancing from "./pages/products/po-financing";
import WorkingCapitalLoan from "./pages/products/working-capital-loan";
import Partner from "./pages/partner";
import Complaint from "./pages/complaint";
import Tnc from "./pages/tnc";
import Privacy from "./pages/privacy";
import About from "./pages/about";
import Contact from "./pages/contact";
import Page404 from "./pages/page_404";
import Riplay from "./pages/riplay";
import Faq from "./pages/faq";
import Redirect from "./components/direct-link";
// import { register } from "module";
export const NAVIGATIONS: { [key: string]: string } = {
  home: "/",
  about: "/about",
  contact: "/contact",
  partner: "/partner",
  complaint: "/complaint",
  tnc: "/tnc",
  privacy: "/privacy-policy",
  employeeLoan: "/product/employee-loan",
  invoiceFinancing: "/product/invoice-financing",
  poFinancing: "/product/po-financing",
  capitalLoan: "/product/working-capital-loan",
  riplay: "/riplay",
  faq: "/faq",
  login: "/login",
  register : "/register/*"
};

const NAVIGATION_ELEMENTS: Record<string, JSX.Element> = {
  home: <Home />,
  about: <About />,
  contact: <Contact />,
  partner: <Partner />,
  complaint: <Complaint />,
  tnc: <Tnc />,
  privacy: <Privacy />,
  employeeLoan: <EmployeeLoan />,
  invoiceFinancing: <InvoiceFinancing />,
  poFinancing: <PoFinancing />,
  capitalLoan: <WorkingCapitalLoan />,
  riplay: <Riplay/>,
  faq: <Faq/>,
  login: <Redirect  />,
  register: <Redirect  />
  
};
function App() {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <div id="site-content">
        <Routes>
          {Object.keys(NAVIGATIONS).map((n) => {
            if (!NAVIGATION_ELEMENTS[n]) {
              return null;
            }
            return (
              <Route
                path={NAVIGATIONS[n]}
                element={NAVIGATION_ELEMENTS[n]}
                key={NAVIGATIONS[n]}
              />
            );
          })}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { PRODUCTS } from "../../constants/products";
import ProductCard from "../../components/products/product-card";

function HomeProduct() {
  return (
    <div className="my-4 py-4">
      <Container>
        <div className="text-center text-size-title-2 mb-4 mb-lg-5 fw-extra-bold">
          Produk Kami
        </div>
        <Row className="justify-content-center">
          <Col xl={9} lg={10} md={12}>
            <Row>
              {Object.keys(PRODUCTS).map((p) => {
                const productInfo = PRODUCTS[p];
                return (
                  <Col key={p} lg={6} md={6} sm={12} xs={12} className="p-2">
                    <ProductCard product={productInfo} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeProduct;

import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router";
import { NAVIGATIONS } from "../../..";
import imageLists from "../../../assets/images/partner/list.json";
import ProductCard from "../../../components/products/employee-card";

interface IProps {
  data: Record<string, any>;
}

function ProductPartner({ data }: IProps) {
  const foldersToInclude = ["food_beverages", "hotel", "healthcare", "others"];

  const images = Object.entries(imageLists)
    .filter(
      ([folderName, content]) =>
        foldersToInclude.includes(folderName) && content !== null
    )
    .flatMap(([folderName, content]) => {
      return Object.values(content)
        .filter((entry: any) => entry.products.includes(`${data.product}`))
        .map((entry: any) => ({
          src: require(
            `../../../assets/images/partner/${folderName}/${entry.file}`
          ),
          products: entry.products,
        }));
    })
    .slice(0, 6);


  const employeeCard = [
    {
      bannerDescription:
        "",
      bannerImage:
        "",
      description:
        "",
      key: "contact",
      link: "",
      shortDescription: "Bantu karyawan mendapatkan pendanaan dengan cepat",
      shortImage: require(`../../../assets/images/company.png`),
      title: "",
      buttonText: "Daftar sebagai Perusahaan",
    },
    {
      bannerDescription:
        "Kembangkan bisnis anda dengan tambahan modal usaha dari Pohon Dana",
      bannerImage:
        "/static/media/working-capital-loan-banner.0985d41e08f59d79ed50.png",
      description:
        "Pinjaman modal kerja yang dibutuhkan untuk menjaga kelancaran operasional sehari-hari, terutama saat menghadapi fluktuasi arus kas atau penurunan musiman.",
      key: "",
      link: "https://app.pohondana.id/register/borrower/employee",
      shortDescription: "Dapatkan tambahan modal dengan cepat",
      shortImage: require(`../../../assets/images/employee.png`),
      title: "",
      buttonText: "Daftar sebagai Peminjam",
    },
  ];
  return (
    <React.Fragment>
      <Container>
        <Row className="justify-content-center">
          <Col xl={10} lg={11} md={12}>
            <div className="my-5 text-center">
              <div className="fw-extra-bold text-size-title-2 mb-1 text-secondary-dark">
                {data.title1}
              </div>
              <div className="fw-extra-bold text-size-title-2 mb-4 text-secondary-dark">
                {data.title2}
              </div>

              <div className="mt-2 text-size-sub-body-2 text-secondary">
                {data.titleDescription}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center text-center justify-content-center">
          {images.map((a, idx) => (
            <Col
              xl={2}
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="mt-4 mb-2"
              key={`partner-${idx}`}
            >
              <div className="">
                <Image src={a.src} fluid className="mt-2" />
              </div>
            </Col>
          ))}
          <div className="mt-2 text-size-sub-body-2 text-secondary">
            {data.description}
          </div>

          <div className="mt-4 text-center text-size-sub-body-2">
            <Link to={`${NAVIGATIONS.partner}?filter=${data.product}`}>Tampilkan Semua</Link>
          </div>
        </Row>
        {data.product === "employee-loan" && (
          <Row className="justify-content-center mt-5 pt-4">
            {employeeCard.map((p) => {
              return (
                <Col
                  key={`more-product-${p.key}`}
                  lg={4}
                  md={6}
                  sm={12}
                  className="mt-4"
                >
                  <ProductCard product={p} onClickCard={() => null} />
                </Col>
              );
            })}
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
}

export default ProductPartner;

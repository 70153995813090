import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import moment from "moment";
import "moment/locale/id";
import heroBackground from "../../assets/images/hero-background-reverse.png";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { formatNumber } from "../../helpers/formatter";

const birthDate = "2018-02-06";

function HomePerkembangan() {
  const statistics = useSelector(
    (state: RootState) => state.data?.data?.statistics
  );
  if (!statistics) return null;
  return (
    <div
      className="hero-background-reverse-container my-4 pb-5 text-white"
      style={{
        backgroundImage: `url(${heroBackground})`,
      }}
    >
      <Container className="pt-5 mt-0">
        <div className="text-size-body-head mb-2 mb-lg-4 text-center text-lg-start">
          Perkembangan
        </div>
        <Row className="justify-content-center">
          <Col xl={5} lg={4} md={12} sm={12}>
            <div className="text-size-title-2 fw-extra-bold mb-4 mb-lg-0 text-center text-lg-start">
              {moment().diff(birthDate, "year")}th Membangun Kepercayaan
            </div>
          </Col>
          <Col xl={7} lg={8} md={10} sm={12}>
            <Row>
              {(statistics?.values || []).map((s: any, sIdx: number) => {
                let value = s.value;
                if (s.isPercentage) {
                  value = Intl.NumberFormat("id-ID").format(s.value) + "%";
                } else {
                  value = formatNumber(s.value, {
                    notation: "compact",
                    compactDisplay: "short",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  });
                  if (s.isCurrency) {
                    value = "Rp " + value;
                  }
                }
                return (
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    key={`stats-${sIdx}`}
                    className="mb-4"
                  >
                    <div className="fw-extra-bold text-size-sub-headline">
                      {value}
                    </div>
                    <div className="text-size-sub-body-2">{s.title}</div>
                  </Col>
                );
              })}
            </Row>
            <div className="text-end text-size-label mt-4">
              Data diperbaharui:{" "}
              {moment(statistics.last_update).locale("id").format("MMMM YYYY")}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomePerkembangan;

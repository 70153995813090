import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import "moment/locale/id";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

function AboutFinancial() {
  const data = useSelector((state: RootState) => state.data?.data?.financial);
  

  const asOfComponent = (
    <div className="text-secondary-dark text-size-body-head-2 mb-4">
      PER {moment(data ? data.reportDate : "").format("DD MMMM YYYY")}
    </div>
  );
  const titleComponent = (title: string) => {
    return (
      <div className="mb-5 pb-4">
        <div className="text-secondary-dark text-size-sub-body-2">
          (Dinyatakan dalam jutaan Rupiah)
        </div>
        <div className="text-secondary-dark text-size-body-head-2 fw-bold">
          LAPORAN {title}
        </div>
      </div>
    );
  };
  const tableBorderComponent = (
    <tr>
      <td colSpan={2}>
        <div className="border-top border-2 border-dark" />
      </td>
    </tr>
  );
  const tableEmptyRowComponent = (
    <tr>
      <td colSpan={2}>
        <div />
      </td>
    </tr>
  );
  const totalAset = data ? data.financialStatement.assets.reduce(
    (a: number, b: any) => a + b.value,
    0
  ) : "";
  const totalLiabilities = data ? data.financialStatement.liabilities.reduce(
    (a: number, b: any) => a + b.value,
    0
  ) : "";
  const totalProfitAndLoss = data ? data.profitAndLoss.reduce(
    (a: number, b: any) => a + b.value,
    0
  ) : "";
  const displayValueComponent = (value: number) => {
    let displayValue = Intl.NumberFormat("id-ID").format(Math.abs(value));
    if (value < 0) {
      displayValue = `(${displayValue})`;
    }
    return displayValue;
  };
  return (
    <React.Fragment>
      {
        data &&
        <>
        <div className="text-size-title-2 text-center text-secondary-dark mb-4 mb-lg-5">
        Laporan Keuangan
      </div>
      <Card>
        <Card.Body className="p-4">
          <Row className="justify-content-center">
            <Col md={6} sm={12} className="mb-4 mb-md-0">
              {asOfComponent}
              {titleComponent("POSISI KEUANGAN")}
              <Table borderless className="w-100 table-financial" size="sm">
                <tbody>
                  <tr>
                    <td colSpan={2} className="text-size-sub-body-2">
                      ASET
                    </td>
                  </tr>
                  {data.financialStatement.assets.map((a: any, idx: number) => {
                    return (
                      <tr key={`assets-${idx}`}>
                        <td className="text-size-caption">{a.name}</td>
                        <td className="text-size-caption text-end">
                          {displayValueComponent(a.value)}
                        </td>
                      </tr>
                    );
                  })}
                  {tableBorderComponent}
                  <tr>
                    <td className="text-size-sub-body-2 fw-bold">Total Aset</td>
                    <td className="text-size-sub-body-2 fw-bold text-end">
                      {displayValueComponent(totalAset)}
                    </td>
                  </tr>
                  {tableEmptyRowComponent}
                  <tr>
                    <td colSpan={2} className="text-size-sub-body-2">
                      LIABILITAS
                    </td>
                  </tr>
                  {data.financialStatement.liabilities.map(
                    (a: any, idx: number) => {
                      return (
                        <tr key={`liabilities-${idx}`}>
                          <td className="text-size-caption">{a.name}</td>
                          <td className="text-size-caption text-end">
                            {displayValueComponent(a.value)}
                          </td>
                        </tr>
                      );
                    }
                  )}
                  {tableBorderComponent}
                  <tr>
                    <td className="text-size-sub-body-2 fw-bold">
                      Total Liabilitas
                    </td>
                    <td className="text-size-sub-body-2 fw-bold text-end">
                      {displayValueComponent(totalLiabilities)}
                    </td>
                  </tr>
                  {tableEmptyRowComponent}
                  <tr>
                    <td className="text-size-sub-body-2">EKUITAS</td>
                    <td className="text-size-sub-body-2 fw-bold text-end">
                      {displayValueComponent(totalAset - totalLiabilities)}
                    </td>
                  </tr>
                  {tableEmptyRowComponent}
                  {tableBorderComponent}
                  <tr>
                    <td className="text-size-sub-body-2">
                      Total Liabilitas & Ekuitas
                    </td>
                    <td className="text-size-sub-body-2 fw-bold text-end">
                      {displayValueComponent(totalAset)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={6} sm={12} className="mb-4 mb-md-0">
              {asOfComponent}
              {titleComponent("LABA RUGI KOMPREHENSIF")}
              <Table borderless className="w-100 table-financial" size="sm">
                <tbody>
                  {data.profitAndLoss.map((a: any, idx: number) => {
                    return (
                      <tr key={`profitAndLoss-${idx}`}>
                        <td className="text-size-caption">{a.name}</td>
                        <td className="text-size-caption text-end">
                          {displayValueComponent(a.value)}
                        </td>
                      </tr>
                    );
                  })}
                  {tableBorderComponent}
                  <tr>
                    <td className="text-size-sub-body-2">
                      JUMLAH LABA KOMPREHENSIF TAHUN BERJALAN
                    </td>
                    <td className="text-size-sub-body-2 fw-bold text-end">
                      {displayValueComponent(totalProfitAndLoss)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <div className="text-size-sub-body-2 mt-4 fw-bold">
            Opini Audit 2023
          </div>
          <div className="text-size-sub-body-2 mt-2">{data.auditOpinion}</div>
        </Card.Body>
      </Card>
        </>
      }
      
    </React.Fragment>
  );
}

export default AboutFinancial;

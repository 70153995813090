import React from "react";
import { Col, Row } from "react-bootstrap";
import { PRODUCTS } from "../../constants/products";
import ProductCard from "./product-card";

interface IProps {
  onClose: () => void;
}

function WorkingCapitalError({ onClose }: IProps) {
  return (
    <React.Fragment>
      <div className="mb-4 text-size-sub-body-2 text-center">
        Mohon maaf, kami tidak dapat melanjutkan registrasi karena data yang
        anda isi tidak sesuai dengan persyaratan pendaftaran kami.
      </div>
      <div className="mb-4 text-size-sub-body-2 text-center">
        Cek produk lainnya dari Pohon Dana untuk membantu perkembangan bisnis
        anda
      </div>
      <Row>
        <Col lg={6} md={6} sm={12} xs={12} className="p-2">
          <ProductCard
            product={PRODUCTS.invoiceFinancing}
            short
            onClickCard={onClose}
            onNavigate={onClose}
          />
        </Col>
        <Col lg={6} md={6} sm={12} xs={12} className="p-2">
          <ProductCard
            product={PRODUCTS.poFinancing}
            short
            onClickCard={onClose}
            onNavigate={onClose}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default WorkingCapitalError;

import React from "react";
import { Col, Row } from "react-bootstrap";

export interface ComplaintStepContent {
  title: string;
  description: string;
  stepNumber:string;
}

interface IProps {
  contents: ComplaintStepContent[];
}

function ComplaintStep({  contents }: IProps) {
  let sizeLg = 12 / contents.length;
  if (sizeLg < 4) {
    sizeLg = 4;
  }
  return (
    <React.Fragment>
     
      <Row className="justify-content-center">
        {contents.map((c, idx) => {
          let style: any = { marginBottom: 35 };
           return (
            <React.Fragment key={`content-product-benefit-${idx}`}>
              <Col
                xl={sizeLg}
                lg={sizeLg}
                md={4}
                s={12}
                xs={12}
                style={style}
                className=""
              >
                <div className="position-relative h-100">
                  <div className="complaint-card">
                    <div className="position-absolute complaint-number">
                        {c.stepNumber}
                    </div>
                    <div className="fw-extra-bold text-size-body-head mb-2 text-secondary-dark">
                      {c.title}
                    </div>
                    <div className="text-size-sub-body-2 text-primary">
                      {c.description}
                    </div>
                  </div>
                </div>
              </Col>
             
            </React.Fragment>
          );
        })}
      </Row>
    </React.Fragment>
  );
}

export default ComplaintStep;

import React, { ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";

interface IProps {
  title: ReactElement;
  interestMin: number;
  interestMax: number;
  caption: ReactElement;
  position: "left" | "right";
}

function ProductInterest({
  title,
  interestMax,
  interestMin,
  caption,
  position,
}: IProps) {
  return (
    <React.Fragment>
      <div className="bg-secondary-o-lighter py-4 py-lg-5">
        <Container>
          <Row
            className={`align-items-center ${position === "right" ? "flex-row-reverse" : ""}`}
          >
            <Col lg={6} md={6} sm={12}>
              <div
                className={`text-size-title-2 fw-extra-bold text-secondary-dark ${position === "right" ? "text-center text-lg-end" : "text-center text-lg-start"}`}
              >
                {title}
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="mt-4 mt-md-0">
              <div className="text-primary text-center mb-2 text-size-body-head-2">
                Bunga Pinjaman
              </div>
              <div className="text-primary-main text-center mb-2 text-size-sub-headline fw-bold">
                {Intl.NumberFormat("id-ID").format(interestMin)}% -{" "}
                {Intl.NumberFormat("id-ID").format(interestMax)}%
              </div>
              <div className="text-primary text-center mb-2 text-size-sub-body-2">
                Per bulan
              </div>
              <div className="text-primary text-center text-size-sub-body-2">
                {caption}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-2">
        <Row>
          <Col
            xl="auto"
            lg="auto"
            md="auto"
            sm={12}
            className="text-size-sub-body-2 fst-italic"
          >
            Rate Disclaimer:
          </Col>
          <Col xl lg md sm={12} className="text-size-sub-body-2 fst-italic">
            Semua pinjaman tunduk pada persetujuan kredit. Suku bunga dapat
            berubah sewaktu-waktu dan tanpa pemberitahuan. Tingkat bunga yang
            berlaku sekarang adalah sesuai dengan kondisi tingkat bunga pasar
            umumnya dan tergantung pada latar belakang peminjam, jangka waktu
            pinjaman, tujuan dan type pinjaman itu sendiri
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ProductInterest;

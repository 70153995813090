import React from "react";
import { Col, Row } from "react-bootstrap";

const TEAM = [
  {
    name: "Victoria Tahir",
    position: "Presiden Komisaris",
    description:
      "Lulusan University of California (Business and Administration) dan University of Southern California (Accounting) ini memiliki pengalaman lebih dari 20 tahun sebagai pimpinan di bidang keuangan, antara lain di PT. Nico Central, PT. Inti Dufree Promosindo, PT. Sona Topas Tourism Industry, Tbk., PT. Wahana Mediatama, PT. Petarung Tangguh Persada, dan PT. Pancaran Kreasi Adiprima. Kini beliau dipercaya menjabat sebagai Presiden Komisaris PT Pohon Dana Indonesia sejak berdiri tahun 2018.",
  },
  {
    name: "Suwito",
    position: "Komisaris",
    description:
      "Dengan menyandang gelar BSc. Accounting dan MBA Finance dari University of Missouri-Kansas City USA, beliau memiliki pengalaman lebih dari 29 tahun di berbagai perusahaan besar di Indonesia seperti PT Red Planet Indonesia Tbk, PT Republik Capital Indonesia dan CBRE Inc. (NYSE:CBRE) di Los Angeles dan Hong Kong. Saat ini beliau dipercaya untuk menjabat sebagai komisaris PT Pohon Dana Indonesia.",
  },
  {
    name: "Fina Valentin",
    position: "Presiden Direktur",
    description:
      "Lulus dengan gelar Sarjana Akuntansi dari Universitas Atmajaya, di Jakarta, beliau telah mengukir karir di bidang keuangan selama lebih dari 10 tahun. Beliau pernah bekerja sebagai tim finance di PT Panca Nabati Prakarsa dan sebagai finance, accounting, and tax di PT Pancaran Kreasi Adiprima. Selain itu, beliau juga memiliki pengalaman sebagai auditor di KAP Gani Sigiro & Handayani. Saat ini beliau dipercaya untuk memimpin Pohon Dana Indonesia sebagai Presiden Direktur.",
  },
  {
    name: "Tigor Franky",
    position: "Direktur IT",
    description:
      "Kepala divisi Informasi Teknologi Pohon Dana telah memiliki pengalaman lebih dari 24 tahun di bidang teknologi informatika. Beliau memulai karir sebagai programmer dan pengembang sistem di Jatis Solutions. Beliau juga pernah menjabat di posisi manager dan direktur IT di beberapa perusahaan di Indonesia, seperti Bess Finance, Trikomsel, Danon, serta JMC Technologies di Singapore. Kini beliau dipercaya untuk memimpin divisi teknologi di PT Pohon Dana Indonesia.",
  },
];
function AboutTeam() {
  return (
    <React.Fragment>
      <div className="text-size-title-2 text-center fw-extra-bold text-secondary-dark mb-4 mb-lg-5">
        Tim Kami
      </div>
      {/* <Row className="justify-content-center d-flex">
        {TEAM.map((t, idx) => {
          return (
            <Col md={6} sm={12} className="mt-4 h-100 d-flex " key={`team-${idx}`}>
              <div className="h-100 about-card d-flex flex-column">
                <div className="text-size-body-head fw-bold text-secondary-dark">
                  {t.name}
                </div>
                <div className="text-size-sub-body-2 text-secondary mb-4">
                  {t.position}
                </div>
                <div className="text-size-sub-body-2 text-primary flex-grow-1">
                  {t.description}
                </div>
              </div>
            </Col>
          );
        })}
      </Row> */}
      <Row className="justify-content-center d-flex">
  {TEAM.map((t, idx) => {
    return (
      <Col md={6} sm={12} className="mt-4 d-flex" key={`team-${idx}`}>
        <div className="h-100 about-card d-flex flex-column">
          <div className="text-size-body-head fw-bold text-secondary-dark">
            {t.name}
          </div>
          <div className="text-size-sub-body-2 text-secondary mb-4">
            {t.position}
          </div>
          <div className="text-size-sub-body-2 text-primary flex-grow-1">
            {t.description}
          </div>
        </div>
      </Col>
    );
  })}
</Row>
    </React.Fragment>
  );
}

export default AboutTeam;

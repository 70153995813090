import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Alert, Modal, Image } from "react-bootstrap";
import { ENV } from "../../config/env";
import sendIcon from "../../assets/images/send-message.png";

function ContactForm() {
  const [formMessage, setFormMessage] = useState({
    name: "",
    email: "",
    phone_number: "",
    subject: "",
    message: "",
  });
  const [errorsName, setErrorsName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [errorSubject, setErrorSubject] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageGeneral, setErrorMessageGeneral] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const submitForm = async () => {
    let hasError = false;

    if (formMessage.name === "") {
      setErrorsName("Harap isi bagian ini");
      hasError = true;
    } else {
      setErrorsName("");
    }

    if (formMessage.email === "") {
      setErrorEmail("Harap isi bagian ini");
      hasError = true;
    } else {
      setErrorEmail("");
    }

    if (formMessage.phone_number === "") {
      setErrorPhoneNumber("Harap isi bagian ini");
      hasError = true;
    } else {
      setErrorPhoneNumber("");
    }

    if (formMessage.subject === "") {
      setErrorSubject("Harap isi bagian ini");
      hasError = true;
    } else {
      setErrorSubject("");
    }

    if (formMessage.message === "") {
      setErrorMessage("Harap isi bagian ini");
      hasError = true;
    } else {
      setErrorMessage("");
    }

    if (!hasError) {
      let response = await axios.post(
        `${ENV.API_URL}/contact/send-message`,
        formMessage,
        {
          headers: {
            // 'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data.code === 200) {
        setIsSuccess(true);
        setFormMessage({
          ...formMessage,
          name: "",
          email: "",
          phone_number: "",
          subject: "",
          message: "",
        });
      } else {
        setErrorMessageGeneral("Maaf, ada pesan Anda tidak dapat terkirim.");
      }
    }
  };

  return (
    <React.Fragment>
      <h1 className="text-size-title-2 fw-extra-bold">Kontak Kami</h1>
      <div className="mt-2 text-size-sub-body-2 text-secondary">
        Ada pertanyaan atau masukan? Silahkan hubungi kami. Kami siap membantu
        anda
      </div>
      <div className="contact-form py-3">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
          className="contactForm"
        >
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Nama*"
              onChange={(e) => {
                setFormMessage({ ...formMessage, name: e.target.value });
              }}
              value={formMessage.name}
            />
            <div className="error-message">{errorsName}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Alamat Email*"
              value={formMessage.email}
              onChange={(e) => {
                setFormMessage({ ...formMessage, email: e.target.value });
                if (
                  !new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(
                    e.target.value
                  )
                ) {
                  setErrorEmail("Masukkan format email yang benar");
                } else {
                  setErrorEmail("");
                }
              }}
            />
            <div className="error-message">{errorEmail}</div>
          </Form.Group>
          <Form.Group className="mb-3 phone-input-group">
            <div className="phone-wrapper">
              <div className="country-code px-2">+62</div>
              <Form.Control
                className="phone-number-input"
                placeholder="Nomor Telepon*"
                value={formMessage.phone_number}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setFormMessage({
                      ...formMessage,
                      phone_number: e.target.value,
                    });
                  }
                }}
              />
            </div>
            <div className="error-message">{errorPhoneNumber}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Subjek*"
              value={formMessage.subject}
              onChange={(e) => {
                setFormMessage({ ...formMessage, subject: e.target.value });
              }}
            />
            <div className="error-message">{errorSubject}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Pesan"
              as="textarea"
              rows={3}
              value={formMessage.message}
              onChange={(e) => {
                setFormMessage({ ...formMessage, message: e.target.value });
              }}
            />
            <div className="error-message">{errorMessage}</div>
          </Form.Group>
          {errorMessageGeneral && (
            <Alert show variant="danger">
              {errorMessageGeneral}
            </Alert>
          )}

          <div className="text-center py-3">
            <Button variant="primary" size="lg" type="submit" className="w-100">
              Kirim Pesan
            </Button>
          </div>
        </Form>
      </div>
      {isSuccess && (
        <Modal show onHide={() => setIsSuccess(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pt-0">
            <div className="text-center">
              <Image src={sendIcon} />
            </div>
            <div className="text-center mt-2 text-size-body-head-2 mb-4 text-primary">
              Terima kasih
            </div>
            <div className="text-center mt-2 text-size-sub-body-2 text-primary">
              Pesan anda telah kami terima dan akan kami proses secepatnya
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default ContactForm;
